import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

const Meridian = () => {
  return (
    <>

<Navbar />

   {/* ------------------------------------------------------------------- */}

   <div className="heading-main-box" style={{marginTop:'20px'}}>
        <h5 style={{ color: "#000" }}>Learnorama</h5>
        <h1>Tech Generation Summer Camp For Kids 2024</h1>
        <h1>at MERIDIAN SCHOOL (UPPAL)</h1>
        <h3>High School Summer Camp Activities</h3>
        <span style={{ fontSize: "16px" }}>(5th to 9th class)</span>
      </div>

      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography className="activities-para-boxs">
            <img src="./images/downloadfile-35.jpg" alt="About" />
            <h1 style={{ fontSize: "22px", color: "#000" }}>
              Exploring AI tools <br />{" "}
              <span style={{ fontSize: "14px" }}>
                (Tech Excellence Bright Future)
              </span>
            </h1>
            <p>
              Uncover the future with Exploring AI Tools! Embrace tech
              excellence today for a brighter tomorrow. Dive into the world of
              Artificial Intelligence, where innovation meets limitless
              possibilities.
            </p>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography className="activities-para-boxs">
            <img src="./images/downloadfile-51.png" alt="About" />
            <h1 style={{ fontSize: "22px", color: "#000" }}>
              Coding <br />{" "}
              <span style={{ fontSize: "14px" }}>
                (Code Today Lead Tomorrow)
              </span>
            </h1>

            <p>
              Crack the code to success! With Coding, your journey from today
              leads to leadership tomorrow. Unleash the power of coding and set
              the stage for a future filled with endless opportunities
            </p>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography className="activities-para-boxs">
            <img src="./images/downloadfile-46.jpg" alt="About" />
            <h1 style={{ fontSize: "22px", color: "#000" }}>
              Microsoft <br />{" "}
              <span style={{ fontSize: "14px" }}>
                (Tech Prowess Starts Here)
              </span>
            </h1>
            <p>
              Begin your tech journey with Microsoft! It's where tech prowess
              starts, opening doors to a world of possibilities. Explore the
              vast landscape of Microsoft technologies and chart your course
              towards excellence.
            </p>
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography className="activities-para-boxs">
            <img src="./images/downloadfile-37.jpg" alt="About" />
            <h1 style={{ fontSize: "22px", color: "#000" }}>
              Robotics <br />{" "}
              <span style={{ fontSize: "14px" }}>
                (Where Mechanics Meet Magic)
              </span>
            </h1>
            <p>
              Step into the enchanting realm where mechanics meet magic -
              Robotics! Discover the art of creating machines that come to life.
              Dive into a world of innovation, where your imagination takes
              flight.
            </p>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography className="activities-para-boxs">
            <img src="./images/downloadfile-52.png" alt="About" />
            <h1 style={{ fontSize: "22px", color: "#000" }}>
              Jr Culinary Arts <br />{" "}
              <span style={{ fontSize: "14px" }}>
                (Little Chef, Big Flavors)
              </span>
            </h1>

            <p>
              Unleash the inner chef in your little one with Jr Culinary Arts!
              From tiny hands come big flavors as they explore the world of
              cooking. Nurture a passion for culinary excellence from an early
              age.
            </p>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography className="activities-para-boxs">
            <img src="./images/downloadfile-32.jpg" alt="About" />
            <h1 style={{ fontSize: "22px", color: "#000" }}>
              Jr Space Scientist <br />{" "}
              <span style={{ fontSize: "14px" }}>
                (Budding Stars in the Galaxy of Knowledge)
              </span>
            </h1>

            <p>
              Explore the wonders of space, astronomy, and scientific discovery.
              Nurture young minds as they reach for the stars.
            </p>
          </Typography>
        </Grid>
      </Grid>

      <div className="heading-main-box">
        <h3>Primary School Summer Camp Activities</h3>
        <span style={{ fontSize: "16px" }}>(1st to 4th class)</span>
      </div>

      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography className="activities-para-boxs">
            <img src="./images/downloadfile-53.jpg" alt="About" />
            <h1 style={{ fontSize: "22px", color: "#000" }}>
              Jr Photography <br />{" "}
              <span style={{ fontSize: "14px" }}>
                (Little Lens, Big Vision)
              </span>
            </h1>

            <p>
              Unleash the creative eye with Jr Photography! Where little lenses
              capture big visions, nurturing the next generation of
              photographers.
            </p>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography className="activities-para-boxs">
            <img src="./images/downloadfile-57.jpg" alt="About" />
            <h1 style={{ fontSize: "22px", color: "#000" }}>
              Digital Storytelling <br />{" "}
              <span style={{ fontSize: "14px" }}>
                (Narrate, Create, Innovate)
              </span>
            </h1>

            <p>
              Dive into the world of Digital Storytelling, where imagination
              meets innovation. Learn to narrate compelling tales, create
              engaging content, and innovate through the power of digital
              narratives.
            </p>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography className="activities-para-boxs">
            <img src="./images/downloadfile-54.png" alt="About" />
            <h1 style={{ fontSize: "22px", color: "#000" }}>
              Art and Craft Innovations <br />{" "}
              <span style={{ fontSize: "14px" }}>
                (Crafting Tomorrow's Wonders)
              </span>
            </h1>

            <p>
              Join the journey of Art and Craft Innovations, where young minds
              craft tomorrow's wonders. Explore creativity, express uniqueness,
              and foster innovative thinking through hands-on artistic
              endeavors.
            </p>
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography className="activities-para-boxs">
            <img src="./images/downloadfile-54.jpg" alt="About" />
            <h1 style={{ fontSize: "22px", color: "#000" }}>
              Science Experiments <br />{" "}
              <span style={{ fontSize: "14px" }}>
                (Hands-On Discovery, Minds-On Marvel)
              </span>
            </h1>

            <p>
              Embark on a hands-on journey of discovery with Science
              Experiments! Engage curious minds in marvels of science, fostering
              a love for exploration and learning through interactive
              experiments.
            </p>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography className="activities-para-boxs">
            <img src="./images/downloadfile-55.jpeg" alt="About" />
            <h1 style={{ fontSize: "22px", color: "#000" }}>
              Chess <br />{" "}
              <span style={{ fontSize: "14px" }}>
                (Master the Board, Rule the Game)
              </span>
            </h1>

            <p>
              Conquer the chessboard and rule the game! Master strategic
              thinking, decision-making, and enhance cognitive skills through
              the timeless game of chess.
            </p>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography className="activities-para-boxs">
            <img src="./images/downloadfile-56.png" alt="About" />
            <h1 style={{ fontSize: "22px", color: "#000" }}>
              Communication Skills <br />{" "}
              <span style={{ fontSize: "14px" }}>
                (Speak with Impact, Connect with Influence)
              </span>
            </h1>

            <p>
              Elevate communication skills to new heights! Learn to speak with
              impact and connect with influence, empowering individuals to
              express themselves confidently and effectively in various aspects
              of life.
            </p>
          </Typography>
        </Grid>
      </Grid>

{/* ------------------------------------------------------------------- */}

<Footer />
   </>
  )
}

export default Meridian;