import React, { useEffect, useState } from "react";
import "./Registration.css";
import axios from "axios";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';

const Registration = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState("");
  const [school, setSchool] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [loadingSendOtp, setLoadingSendOtp] = useState(false); // State for loading Send OTP
  const [loadingVerifyAndPay, setLoadingVerifyAndPay] = useState(false); // State for loading Verify OTP & Pay
  const [authToken, setAuthToken] = useState(null); // State to store auth token
  const [countdown, setCountdown] = useState(120);

  const navigate = useNavigate(); // Use navigate for redirection

  useEffect(() => {
    let intervalId;

    if (otpSent && countdown > 0) {
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    if (countdown === 0) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [otpSent, countdown]);

  // Function to send OTP
  const sendOtp = async () => {
    setLoadingSendOtp(true); // Set loading state for Send OTP
    try {
      const response = await axios.post(
        "https://learnoramabackend.learnorama.in/api/user/signup/send-otp",
        {
          email,
        }
      );
      alert("OTP sent to your email");
      setOtpSent(true);
    } catch (error) {
      handleApiError(error, "Error sending OTP");
    } finally {
      setLoadingSendOtp(false); // Reset loading state for Send OTP
    }
  };

  // Function to verify OTP and initiate payment
  const verifyAndPay = async () => {
    setLoadingVerifyAndPay(true); // Set loading state for Verify OTP & Pay
    try {
      // Verify OTP
      const verifyResponse = await axios.post(
        "https://learnoramabackend.learnorama.in/api/user/verify-otp",
        {
          name,
          email,
          otp,
          phone,
          school,
        }
      );

      // Extract token from response
      const token = verifyResponse.data.user.token;
      const encryptedToken = CryptoJS.AES.encrypt(token, 'hello').toString();
      localStorage.setItem('authToken', encryptedToken);

      alert("Registration successful and OTP verified");

      // Initiate payment using the obtained token
      const paymentResponse = await axios.post(
        "https://learnoramabackend.learnorama.in/api/user/payment/init",
        {
          amount: 1,
          volume_id: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Use the token for authorization
          },
        }
      );

      setLoadingVerifyAndPay(false); // Reset loading state for Verify OTP & Pay
      window.location.href = paymentResponse.data.redirectUrl;

      // After successful payment, redirect to the welcome page
      window.addEventListener("load", () => {
        navigate("/welcome");
      });

    } catch (error) {
      setLoadingVerifyAndPay(false); // Reset loading state for Verify OTP & Pay on error
      handleApiError(error, "Error verifying OTP or initiating payment");
    }
  };

  // Function to handle API errors and display appropriate messages
  const handleApiError = (error, defaultMessage) => {
    if (error.response && error.response.data && error.response.data.message) {
      alert(defaultMessage + ": " + error.response.data.message);
    } else if (error.request) {
      alert(defaultMessage + ": No response received");
    } else {
      alert(defaultMessage + ": " + error.message);
    }
  };

  return (
    <>
      <Navbar />
      <h1 className="head-001">Future Proof AI Skills: Junior Prompt Engineering- Volume 1 PDF</h1>
      <p
        style={{
          textAlign: "center",
          fontWeight: "bold",
          background: "yellow",
          padding: "10px",
          width: "70%",
          margin: "auto",
          marginTop: "10px",
          marginBottom: "10px",
        }}>
        Note: Every student who subscribes to the course will undergo an online
        exam. Qualified students will receive a Jr. Prompt Engineer certificate.
      </p>
      <div className="registration-form">
        <h2>Registration</h2>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            onClick={sendOtp}
            disabled={loadingSendOtp}
            className="button-2">
            {loadingSendOtp ? "Sending..." : "Send OTP"}
          </button>
          <p style={{margin:'auto',textAlign:'center',paddingTop:'15px'}}>
            Already have an account?
            <Link
              style={{
                textDecoration: "none",
                color: "#fd7e14",
                margin: "auto",
                textAlign: "center",
              }}
              to="/login">
              <span>Login Now</span>
            </Link>
          </p>
        </div>

        {otpSent && (
          <>
            <div>
              <label>Name:</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            {otpSent && (
          <>
          <div>
              <label>OTP:</label>
              <input
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>
            {countdown > 0 && <p style={{color:'red'}}>Time left for OTP verification: {countdown} seconds</p>}
          </>
        )}
            <div>
              <label>Phone:</label>
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div>
              <label>School:</label>
              <input
                type="text"
                value={school}
                onChange={(e) => setSchool(e.target.value)}
              />
            </div>
            <button
              onClick={verifyAndPay}
              disabled={loadingVerifyAndPay}
              className="button-2">
              {loadingVerifyAndPay ? "Processing..." : "Verify OTP & Pay ₹1"}
            </button>
            <p>
              Already have an account?{" "}
              <Link
                style={{
                  textDecoration: "none",
                  color: "#fd7e14",
                  margin: "auto",
                  textAlign: "center",
                  
                }}
                to="/login">
                <span>{" "}Login Now</span>
              </Link>
            </p>
          </>
        )}
      </div>

      


      <Footer />
    </>
  );
};

export default Registration;
