
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { Grid, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import CryptoJS from 'crypto-js';


const Welcome = () => {

  const navigate = useNavigate();

  useEffect(() => {
    const encryptedToken = localStorage.getItem("authToken");
    if (encryptedToken) {
      // Decrypt token
      try {
        const bytes = CryptoJS.AES.decrypt(encryptedToken, 'hello');
        const token = bytes.toString(CryptoJS.enc.Utf8);

        console.log("Decrypted Token:", token); // Debugging line
        // Verify token or do additional checks if needed
        // If token is valid, allow access; otherwise, redirect to login
      } catch (e) {
        console.error("Error decrypting token:", e);
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <>
    <Navbar />
    
    <div className="heading-main-box" style={{marginTop:'50px'}}>
        <h5 style={{color:'#000'}}> Welcome <br/> to </h5>
        <h1>"ARTIFICIAL INTELLIGENCE AND TECHNOLOGY INSTITUTE OF LEARNORAMA"</h1>
        <p
        style={{
          textAlign: "center",
          fontWeight: "bold",
          background: "yellow",
          padding: "10px",
          width: "70%",
          margin: "auto",
          marginTop: "10px",
          marginBottom: "10px",
        }}>
        Note: Every student who subscribes to the course will undergo an online
        exam. Qualified students will receive a Jr. Prompt Engineer certificate.
      </p>
      </div>

      <Grid container style={{width:'90%',margin:'auto'}}>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography className="activities-para-boxs-online">
          <img src="./images/online/class-1-main-img.jpg" alt="AI" />
            <h1 style={{color:'#ff9a2f'}}>Future Proof AI Skills: Junior Prompt Engineering- Volume 1</h1>
            <Link style={{ textDecoration: "none" }} to="/vol01chapters">
            <button style={{ marginTop: "15px" }} className="button-1">
              Watch Now
            </button>
          </Link>
            
          </Typography>
        </Grid>
{/* 
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography className="activities-para-boxs-online">
          <img src="./images/online/class-5-main-GPT-img.jpg" alt="CODING" />
            <h1 style={{fontSize:'22px',color:'#ff9a2f'}}>ChatGPT</h1>
            <Link style={{ textDecoration: "none" }} to="/chatGPT">
            <button style={{ marginTop: "15px" }} className="button-1">
              Watch Now
            </button>
          </Link>
            
          </Typography>
        </Grid> */}

      </Grid>

      <Grid container style={{width:'80%',margin:'auto'}}>
        

        

        
      </Grid>

      

    <Footer />
    </>
  )
}

export default Welcome;