import React from "react";
import Footer from "../components/Footer";
import ScrollAdd from "../components/ScrollAdd";
import Navbar from "../components/Navbar";
import { Grid, Typography } from "@material-ui/core";

const Contact = () => {
  return (
    <>
      <ScrollAdd />
      <Navbar />

      {/* contact start */}

      <Grid container className="about-main-box" id="venue-box"
         style={{backgroundColor:'#000',padding:'20px 10px',borderRadius:'15px',marginTop:'50px'}}>
       
        
        <Grid
          item
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{ margin: "auto", padding: "5px" }}>
          <Typography style={{ textAlign: "center" }} className="heading-main-box">
          <h2 style={{ margin: "auto", textAlign: "center", color: "#000" }}>
          Contact Learn Orama Technologies PVT LTD
        </h2>
        <div className="contact-details">
          <p>
            <a
              style={{ textDecoration: "none", color: "#fff" }}
              href="mailto:info@learnorama.in">
              <span>📧 Email:</span>info@learnorama.in
            </a>
          </p>
          <p>
            <p>
            <a
                style={{ textDecoration: "none", color: "#fff" }}
                href="tel:+91 9849856143">
                <span>☎️ Phone:</span>+91 9849856143 <span style={{padding:'5px'}}>|</span>
              </a>
              <a
                style={{ textDecoration: "none", color: "#fff" }}
                href="tel:+91 9949567143">
                 +91 9949567143 <span style={{padding:'5px'}}>|</span>
              </a>
              <a
                style={{ textDecoration: "none", color: "#fff" }}
                href="tel:+91 9959945143">
                 +91 9959945143 
              </a>
            </p>
          </p>
        </div>
        <p style={{ color: "#000" }} className="connect-message">
          Connect with us and give your child an unforgettable summer full of
          learning, growth, and fun!
        </p>
          </Typography>
          
        </Grid>

        <Grid
          item
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{ margin: "auto", textAlign: "center" }}>
          <div className="profile-wrapper-contact" style={{ margin: "auto" }}>
            <div className="profile-contact">
              <div className="profile-image-contact">
                <img style={{width:'100%'}} src="./images/contact-h1-01.png" alt="About" />
              </div>
            </div>
          </div>
        </Grid>

        
      </Grid>

      {/* contact end */}

      {/* Footer */}

      <Footer />
    </>
  );
};

export default Contact;
