import React from 'react';
import Footer from '../components/Footer';
import ScrollAdd from '../components/ScrollAdd';
import Navbar from '../components/Navbar';

const TermsAndConditions = () => {
  return (
    <>
      <ScrollAdd />
      <Navbar />
      
      <div className="terms-and-conditions-container">
        <h1 className="terms-and-conditions-title">Terms and Conditions of Use</h1>
        <p>Welcome to <a href="https://www.learnorama.in">Learnorama.in</a>. By using our website, you agree to comply with and be bound by the following terms and conditions.</p>
        <p>These Terms and Conditions govern your access to and use of Learnorama.in. If you disagree with any part of these terms, please do not use our website.</p>

        <h2 className="terms-and-conditions-subtitle">Definitions</h2>
        <p>The following terminology applies to these Terms and Conditions, Privacy Statement, and Disclaimer Notice:</p>
        <ul>
          <li>"Client," "You," and "Your" refers to the individual accessing Learnorama.in and accepting the terms.</li>
          <li>"The Company," "Ourselves," "We," "Our," and "Us" refers to Learnorama.</li>
          <li>"Party," "Parties," or "Us" refers to both the Client and Learnorama, or either the Client or Learnorama.</li>
        </ul>

        <h2 className="terms-and-conditions-subtitle">Cookies</h2>
        <p>We use cookies to enhance your experience on Learnorama.in. By continuing to use our website, you consent to our use of cookies as outlined in our Privacy Policy.</p>

        <h2 className="terms-and-conditions-subtitle">Intellectual Property</h2>
        <p>Unless stated otherwise, Learnorama.in and/or its licensors hold all intellectual property rights for the content on Learnorama.in. All rights are reserved.</p>
        <p>You may access and use the content for personal purposes only, subject to the restrictions outlined in these terms and conditions.</p>

        <h2 className="terms-and-conditions-subtitle">Prohibited Uses</h2>
        <p>You must not engage in any of the following prohibited activities:</p>
        <ul>
          <li>Reproducing or republishing website material in any format.</li>
          <li>Commercializing, sublicensing, or otherwise exploiting website content.</li>
          <li>Engaging in activities that may harm or damage Learnorama.in.</li>
          <li>Violating applicable laws or regulations through the use of our website.</li>
        </ul>

        <h2 className="terms-and-conditions-subtitle">News and Educational Services</h2>
        <p>Learnorama.in may provide news and educational content, including articles, updates, and resources related to learning technologies, education, and AI. The content is for informational purposes only, and we do not guarantee the accuracy, timeliness, or reliability of any news or information provided. By using these services, you agree that Learnorama is not liable for any errors or omissions in the content, and users should verify any information before acting upon it.</p>

        <h2 className="terms-and-conditions-subtitle">Privacy</h2>
        <p>Your privacy is important to us. Please review our <a href="https://learnorama.in/#/privacypolicy">Privacy Policy</a> to understand how we handle your personal information.</p>

        <h2 className="terms-and-conditions-subtitle">Disclaimer of Warranties</h2>
        <p>Learnorama.in is provided on an "as is" basis, without warranties or representations of any kind. We do not guarantee the accuracy, reliability, or availability of our website or its content.</p>

        <h2 className="terms-and-conditions-subtitle">Limitation of Liability</h2>
        <p>Learnorama.in, its officers, directors, and employees are not liable for any damages arising from your use of or inability to use the website.</p>

        <h2 className="terms-and-conditions-subtitle">Indemnification</h2>
        <p>You agree to indemnify Learnorama.in against any claims, damages, costs, or liabilities resulting from your violation of these terms.</p>

        <h2 className="terms-and-conditions-subtitle">Changes to Terms</h2>
        <p>Learnorama.in reserves the right to modify these terms at any time. You are encouraged to review these terms periodically for any changes.</p>

        <h2 className="terms-and-conditions-subtitle">Governing Law</h2>
        <p>These terms are governed by the laws of Hyderabad, India. Any disputes arising from these terms will be subject to the exclusive jurisdiction of the courts located in Hyderabad.</p>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
