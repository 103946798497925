import React from "react";
import Footer from "../components/Footer";
import ScrollAdd from "../components/ScrollAdd";
import Navbar from "../components/Navbar";

const Faqs = () => {
  return (
    <>
      <ScrollAdd />
      <Navbar />

      <div className="faq-main-box">
        <h1 className="faq-heading">Frequently Asked Questions</h1>

        <div className="faq-item">
          <h5>Q: Who can participate in Learnorama Summer Camp?</h5>
          <p>
            A: Our camp is open to children of all ages and backgrounds. We
            welcome enthusiastic learners who are ready for a summer of fun and
            growth.
          </p>
        </div>

        <div className="faq-item">
          <h5>Q: What are the dates and duration of the camp?</h5>
          <p>
            A: The camp runs from [start date] to [end date]. It's a month-long
            immersive experience filled with diverse activities.
          </p>
        </div>

        <div className="faq-item">
          <h5>Q: Where is Learnorama Summer Camp located in Hyderabad?</h5>
          <p>
            A: Our camp is situated at [venue]. Please refer to our Contact Us
            page for the exact address and directions.
          </p>
        </div>

        <div className="faq-item">
          <h5>
            Q: Can I enroll my child for specific activities, or are they
            required to participate in all of them?
          </h5>
          <p>
            A: Parents have the flexibility to choose specific activities based
            on their child's interests. We offer a variety of options to cater
            to individual preferences.
          </p>
        </div>

        <div className="faq-item">
          <h5>
            Q: Are lodging and boarding facilities available for outstation
            students?
          </h5>
          <p>
            A: Yes, we provide comfortable lodging and boarding facilities for
            students coming from outside Hyderabad. Rest assured, their stay
            will be safe and enjoyable.
          </p>
        </div>

        <div className="faq-item">
          <h5>Q: How can I enroll my child in Learnorama Summer Camp?</h5>
          <p>
            A: Enrollment is easy! Visit our Enrollment page on the website and
            fill out the online form. Secure payment options are available for
            your convenience.
          </p>
        </div>

        <div className="faq-item">
          <h5>
            Q: What COVID-19 safety measures are in place during the camp?
          </h5>
          <p>
            A: The health and safety of our participants are our top priorities.
            We strictly adhere to all COVID-19 guidelines and take necessary
            precautions to create a secure environment.
          </p>
        </div>

        <div className="faq-item">
          <h5>
            Q: Is financial assistance or scholarships available for the camp?
          </h5>
          <p>
            A: We believe in making our camp accessible to all. Please contact
            us directly to discuss any financial assistance or scholarship
            opportunities.
          </p>
        </div>

        <div className="faq-item">
          <h5>Q: Can parents visit the camp during the program?</h5>
          <p>
            A: While parents are encouraged to engage in our Parent's Day,
            regular visits during the camp may disrupt the experience for the
            children. However, special arrangements can be made in certain
            cases.
          </p>
        </div>

        <div className="faq-item">
          <h5>
            Q: What happens in case of an emergency or if my child needs medical
            attention?
          </h5>
          <p>
            A: Our staff is trained to handle emergencies, and we have medical
            professionals on-site. Parents will be immediately notified in case
            of any medical concerns.
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Faqs;
