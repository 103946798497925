import React from "react";
import Footer from "../components/Footer";
import ScrollAdd from "../components/ScrollAdd";
import Navbar from "../components/Navbar";
import Slider from "../components/slider/Slider";
import FactSection from "../components/FactSection";
import { Link } from "react-router-dom";

const Blog = () => {
  return (
    <>
      <ScrollAdd />
      <Navbar />

      {/* blog start */}

      <div style={{ marginTop: "100px" }} className="heading-main-box">
        <h5>Our Blog</h5>
        <h1>Latest Blog & News</h1>
      </div>

      <div className="three-boxes-container" >
        <div className="three-boxes-box three-boxes-box1">
          <Link
            style={{ textDecoration: "none", color: "#000" }}
            to="https://www.andhrajyothy.com/2024/telangana/high-school-students-designed-an-ecommerce-website-1257855.html">
            <div className="card">
              <div className="card-header">
                <img
                  src="https://media.andhrajyothy.com/media/2024/20240511/gd_goenka_school_5f152a1454.jpg"
                  alt="rover"
                />
              </div>

              <div className="card-body">
                <span className="tag tag-teal">ABN ANDHRA JYOTHI</span>
                <p>
                  <span style={{ color: "#fd7e14" }}>
                    Learn Orama Technologies Pvt Ltd
                  </span>{" "}
                  recently conducted an enriching summer camp at GD Goenka School, Hyderabad. The camp offered a diverse range of activities, including rock climbing, river kayaking, trekking, creative arts, and cooking workshops. These activities were thoughtfully curated to provide enjoyment while fostering the development of essential life skills, confidence, and memorable experiences for the students.
                  <br />
                  We are proud to announce that {" "}<span style={{ color: "#fd7e14" }}>Andhra Jyothy</span>{" "} has published an article covering our summer camp, underscoring its success and the positive impact on the participants. You can read the complete article here.
                  <br />
                  Stay tuned for more updates on our upcoming events and
                  activities!
                </p>
                <div className="user">
                  <img
                    src="https://www.andhrajyothy.com/assets/images/logo.png"
                    alt="user"
                  />
                  <div className="user-info">
                    <h5>ABN ANDHRA JYOTHI</h5>
                    <small>2024</small>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className="three-boxes-box three-boxes-box1">
          <Link
            style={{ textDecoration: "none", color: "#000" }}
            to="https://www.sakshi.com/photos/events/school-summer-camp-2024-hyderabad-2057747">
            <div className="card">
              <div className="card-header">
                <img
                  src="https://www.sakshi.com/gallery_images/2024/05/23/school%20summer%20camp%202024%20hyderabad-%20%282%29.jpg"
                  alt="rover"
                />
              </div>

              <div className="card-body">
                <span className="tag tag-teal"> SAKSHI</span>
                <p>
                  <span style={{ color: "#fd7e14" }}>
                    Learn Orama Technologies Pvt Ltd
                  </span>{" "}
                  recently organized an exciting summer camp at GD Goenka
                  School, Hyderabad. The camp was filled with a variety of
                  activities such as rock climbing, river kayaking, trekking,
                  creative arts, and cooking workshops. These activities not
                  only provided fun and adventure but also helped students
                  develop essential life skills, boost their confidence, and
                  make lasting memories.
                  <br />
                  We are thrilled to share that <span style={{ color: "#fd7e14" }}>Sakshi</span>{" "}
                  covered our summer camp, showcasing the event's success and
                  the positive impact it had on the participants. You can view
                  their coverage and photos from the camp here.
                  <br />
                  Stay tuned for more updates on our upcoming events and
                  activities!
                </p>
                <div className="user">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/en/6/6f/Sakshi_np.png"
                    alt="user"
                  />
                  <div className="user-info">
                    <h5>Sakshi</h5>
                    <small>2024</small>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>

        
      </div>

      <div className="three-boxes-container">
        <div className="three-boxes-box three-boxes-box1">
          <Link
            style={{ textDecoration: "none", color: "#000" }}
            to="https://www.thehansindia.com/hans/education-careers/summer-camp-conducted-at-gd-goenka-school-in-hyderabad-881992?infinitescroll=1">
            <div className="card">
              <div className="card-header">
                <img
                  src="https://assets.thehansindia.com/h-upload/2024/05/31/1450860-school-2.webp"
                  alt="rover"
                />
              </div>

              <div className="card-body">
                <span className="tag tag-teal">The HansIndia</span>
                <p>
                  <span style={{ color: "#fd7e14" }}>
                    Learn Orama Technologies Pvt Ltd
                  </span>{" "}
                  recently organized an exciting summer camp at GD Goenka
                  School, Hyderabad. The camp was filled with a variety of
                  activities such as rock climbing, river kayaking, trekking,
                  creative arts, and cooking workshops. These activities not
                  only provided fun and adventure but also helped students
                  develop essential life skills, boost their confidence, and
                  make lasting memories.
                  <br />
                  We are thrilled to share that{" "}
                  <span style={{ color: "blue" }}>The HansIndia</span> wrote an
                  article about our summer camp, highlighting the success and
                  positive impact it had on the participants. You can read the
                  full article here.
                  <br />
                  Stay tuned for more updates on our upcoming events and
                  activities!
                </p>
                <div className="user">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTCkClwpEUiyJDxY46Itvi_MSenzwWscUukzQ&s"
                    alt="user"
                  />
                  <div className="user-info">
                    <h5>The HansIndia</h5>
                    <small>2024</small>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className="three-boxes-box three-boxes-box2">
          {" "}
          <Link style={{ textDecoration: "none", color: "#000" }} to="/blogpg4">
            <div className="card">
              <div className="card-header">
                <img src="./images/RimithReddy.jpeg" alt="rover" />
              </div>

              <div className="card-body">
                <span className="tag tag-teal">Best of Learnorama</span>
                <p>
                  Four outstanding students recently received scholarships from
                  Learnorama for their remarkable achievements. Their
                  dedication, talent, and perseverance serve as inspiring
                  examples of academic and extracurricular excellence,
                  reflecting the transformative power of education. These
                  scholars are poised to make significant contributions to their
                  fields and society as a whole, shaping a brighter future for
                  themselves and others.
                </p>
                <div className="user">
                  <img src="./images/RimithReddy-face-11.jpeg" alt="user" />
                  <div className="user-info">
                    <h5>Rimitha Reddy</h5>
                    <small>2020</small>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className="three-boxes-box three-boxes-box3">
          <Link style={{ textDecoration: "none", color: "#000" }} to="/blogpg5">
            <div className="card">
              <div className="card-header">
                <img src="./images/blog4-img.jpeg" alt="rover" />
              </div>

              <div className="card-body">
                <span className="tag tag-teal">ABN ANDHRA JYOTHI</span>
                <p>
                  Calling all young adventurers! Get ready for a summer filled
                  with excitement and discovery at Learnorama's Primary School
                  Adventure. From junior photography to digital storytelling,
                  art and craft innovations to science experiments, our camp
                  offers a dynamic lineup of activities designed to inspire
                  creativity and ignite curiosity in young learners. Join us as
                  we explore new worlds, unleash our imaginations, and make
                  lifelong memories together. With hands-on activities and
                  expert guidance, children will develop essential skills while
                  having a blast. Let your child's imagination soar at
                  Learnorama's Primary School Adventure!
                </p>
                <div className="user">
                  <img src="./images/blog-4face.jpeg" alt="user" />
                  <div className="user-info">
                    <h5>Advith Reddy</h5>
                    <small>2023</small>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>

      <div className="three-boxes-container">
        <div className="three-boxes-box three-boxes-box1">
          <Link style={{ textDecoration: "none", color: "#000" }} to="/blogpg1">
            <div className="card">
              <div className="card-header">
                <img src="./images/manchu-bg1.jpeg" alt="rover" />
              </div>
              <div className="card-body">
                <span className="tag tag-teal">World Record Holder</span>
                <p>
                  In the world of chess, where strategy meets intellect, Manchu
                  Nirvana, daughter of Tollywood actress Manchu Lakshmi and a
                  student of Dr. Padala Karthik Reddy, is making waves. Not only
                  has she embraced the art of chess with fervor, but she has
                  also etched her name in history as the youngest chess trainer,
                  earning a prestigious certification from the Noble Book of
                  World Records in 2020.
                </p>
                <div className="user">
                  <img src="./images/manchu-face11.jpeg" alt="user" />
                  <div className="user-info">
                    <h5>Manchu Nirvana</h5>
                    <small>2020</small>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className="three-boxes-box three-boxes-box2">
          <Link style={{ textDecoration: "none", color: "#000" }} to="/blogpg2">
            <div className="card">
              <div className="card-header">
                <img src="./images/adharva-bg1.jpeg" alt="rover" />
              </div>
              <div className="card-body">
                <span className="tag tag-teal">Krida Bhushan Puraskar</span>
                <p>
                  In 2023, Adharva Reddy's extraordinary contributions to sports
                  were acknowledged with the prestigious Krida Bhushan Puraskar.
                  This award not only celebrates his individual achievements but
                  also highlights the impact Learnorama's innovative approach to
                  learning can have on nurturing exceptional talents.
                </p>
                <div className="user">
                  <img src="./images/adharva-face11.jpeg" alt="user" />
                  <div className="user-info">
                    <h5>Adharva Reddy</h5>
                    <small>2023</small>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div className="three-boxes-box three-boxes-box3">
          <Link style={{ textDecoration: "none", color: "#000" }} to="/blogpg3">
            <div className="card">
              <div className="card-header">
                <img src="./images/arnika-bg-1.jpeg" alt="rover" />
              </div>
              <div className="card-body">
                <span className="tag tag-teal">Youngest Paraglider</span>
                <p>
                  In 2021, at an age when most kids are exploring the world from
                  the ground, Annika Reddy took to the skies. She shattered
                  records and made history by becoming the world's youngest
                  paraglider. This daring feat not only showcased her
                  fearlessness but also underscored the incredible possibilities
                  that unfold when passion meets education.
                </p>
                <div className="user">
                  <img src="./images/arnika-face11.jpeg" alt="user" />
                  <div className="user-info">
                    <h5>G Annika Reddy</h5>
                    <small>2021</small>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Blog;
