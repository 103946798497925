import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import './LessonDetails.css'; // Import the CSS file
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/Footer';

const LessonDetails = () => {
  const { chapterId, lessonId } = useParams();
  const [lesson, setLesson] = useState(null);
  const [error, setError] = useState(null);
  const [decryptedToken, setDecryptedToken] = useState(null);

  const baseURL = 'https://learnoramabackend.learnorama.in/api/user/videos/V01';

  const decryptToken = () => {
    const encryptedToken = localStorage.getItem('authToken');
    if (encryptedToken) {
      try {
        const bytes = CryptoJS.AES.decrypt(encryptedToken, 'hello');
        return bytes.toString(CryptoJS.enc.Utf8);
      } catch (error) {
        console.error('Error decrypting the token:', error);
        setError('Failed to decrypt the token. Please login again.');
        return null;
      }
    } else {
      console.log('No token found in local storage');
      setError('No token found. Please login again.');
      return null;
    }
  };

  useEffect(() => {
    const token = decryptToken();
    if (token) {
      setDecryptedToken(token);
    }
  }, []);

  useEffect(() => {
    if (!decryptedToken || !chapterId || !lessonId) return;

    console.log(`Chapter ID: ${chapterId}, Lesson ID: ${lessonId}`);

    const cleanedChapterId = chapterId.startsWith('C') ? chapterId : `C${chapterId}`;
    const cleanedLessonId = lessonId.startsWith('L') ? lessonId : `L${lessonId}`;

    const fetchLessonDetails = async () => {
      try {
        const response = await axios.get(
          `${baseURL}/${cleanedChapterId}/${cleanedLessonId}`,
          {
            headers: {
              Authorization: `Bearer ${decryptedToken}`,
            },
          }
        );

        console.log('Lesson data fetched:', response.data);

        if (response.data && response.data.videos && response.data.videos[cleanedLessonId]) {
          const lessonsArray = response.data.videos[cleanedLessonId];
          if (lessonsArray.length > 0) {
            setLesson(lessonsArray[0]);
          } else {
            throw new Error('No lessons found for this ID');
          }
        } else {
          throw new Error('Invalid data format or no lessons found');
        }
      } catch (error) {
        console.error('Error fetching the lesson details:', error);
        if (error.response && error.response.status === 401) {
          setError('Unauthorized. Please check your token.');
        } else if (error.response && error.response.status === 404) {
          setError('Lesson not found. Please check the lesson ID.');
        } else {
          setError('Failed to fetch lesson details. Please try again later.');
        }
      }
    };

    fetchLessonDetails();
  }, [decryptedToken, chapterId, lessonId]);

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);


  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  return (
    <>
    <Navbar />
    
    <div className="lesson-container">
      <h1>LESSON DETAILS</h1>
      {lesson ? (
        <div className="lesson-details">
          <img
            src={`https://learnoramabackend.learnorama.in/videos/${lesson.image}`}
            alt={lesson.title}
            // width={200}
            // height={200}
          />
          <div className="lesson-info">
            
          <video controls controlsList="nodownload" width="600">
                <source
                  src={`https://learnoramabackend.learnorama.in/videos/${lesson.path}`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            <h1 style={{color:'#000'}}>Chapter: {lesson.chapter}</h1>

            <p style={{fontWeight:'bold'}}>Lesson: {lesson.lesson}</p>
            
            <p style={{fontWeight:'bold'}}>Title: {lesson.title}</p>

            <p><span style={{fontSize:'10px',fontWeight:'bold'}}>Description:</span> {lesson.description || 'No description available.'}</p>
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
    
    <Footer />
    
    </>
  );
};

export default LessonDetails;
