import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const images = [
  "./images/g-cam-01.JPG",
  "./images/g-cam-02.JPG",
  "./images/g-cam-03.JPG",
  "./images/g-cam-04.JPG",
  "./images/g-cam-05.JPG",
  "./images/g-cam-06.JPG",
  "./images/g-cam-07.JPG",
  "./images/g-cam-08.JPG",
  "./images/g-cam-09.JPG",
  "./images/g-cam-10.JPG",
  // Add more image URLs as needed
];




const videoIds = [
    'jECp0-_1snQ?si=1Z8THcesCmAI2qEB', // Replace with actual video IDs
    'p2rylmtKAts?si=G4hogMfWYihi_i39',
    'ruc46tWiRh4?si=qJtqsyQ1yx7wHoMn',
    'NprbdgKM1d0?si=tQwexp9xJW2-dbsa'
  ];



const Gallery = () => {

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;
  
      const animatables = document.querySelectorAll('.animatable');
  
      animatables.forEach(animatable => {
        const elementTop = animatable.getBoundingClientRect().top + scrollY;
        const elementBottom = elementTop + animatable.clientHeight;
  
        // Check if element is in the viewport
        if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
          animatable.classList.add('animated');
          animatable.classList.remove('animatable');
        } else {
          // Reset the animation when the element is out of view
          animatable.classList.remove('animated');
          animatable.classList.add('animatable');
        }
      });
    };
  
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Trigger animation on mount
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  const handleLightboxClick = (e) => {
    // Close lightbox if the click is on the overlay, not on the image
    if (e.target.className === 'lightbox') {
      closeLightbox();
    }
  };

  return (
    <>
      <Navbar />

      <h1 className="box-title2">GALLERY</h1>

      <div className="gallery-container">
        <div className="gallery-grid ">
          {images.map((src, index) => (
            <div className="gallery-item block animatable moveUp" key={index} onClick={() => openLightbox(index)}>
              <img src={src} alt={`Gallery image ${index + 1}`} className="gallery-image" />
            </div>
          ))}
        </div>

        {isOpen && (
          <div className="lightbox" onClick={handleLightboxClick}>
            <span className="lightbox-close" onClick={closeLightbox}>&times;</span>
            <img src={images[photoIndex]} alt={`Gallery image ${photoIndex + 1}`} className="lightbox-image" />
            <button className="lightbox-button lightbox-prev" onClick={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}>Previous</button>
            <button className="lightbox-button lightbox-next" onClick={() => setPhotoIndex((photoIndex + 1) % images.length)}>Next</button>
          </div>
        )}

<div className="video-section">
          <h1 className="box-title">Watch Our Videos</h1>
          <div className="video-grid ">
            {videoIds.map(id => (
              <div className="video-item block animatable moveUp" key={id}>
                <iframe
                  title={id}
                  width="100%"
                  height="auto"
                  src={`https://www.youtube.com/embed/${id}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Gallery;
