import React, { useEffect } from 'react';
import './Animations.css'; // Ensure your CSS animations are imported

const Animations = () => {
  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      const animatables = document.querySelectorAll('.animatable');

      animatables.forEach(animatable => {
        const elementTop = animatable.getBoundingClientRect().top + scrollY;
        const elementBottom = elementTop + animatable.clientHeight;

        // Check if element is in the viewport
        if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
          animatable.classList.add('animated');
          animatable.classList.remove('animatable');
        } else {
          // Reset the animation when the element is out of view
          animatable.classList.remove('animated');
          animatable.classList.add('animatable');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Trigger animation on mount

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="wrapper">
      <div className="block animatable bounceIn">bounceIn</div>
      <div className="block animatable bounceInLeft">bounceInLeft</div>
      <div className="block animatable bounceInRight">bounceInRight</div>
      <div className="block animatable fadeIn">fadeIn</div>
      <div className="block animatable fadeInDown">fadeInDown</div>
      <div className="block animatable fadeInUp">fadeInUp</div>
      <div className="block animatable moveUp">moveUp</div>
      <div className="block animatable fadeBgColor">fadeBgColor</div>
      
      {/* Repeat blocks as needed */}
    </div>
  );
};

export default Animations;
