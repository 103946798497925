import React from 'react';
import Footer from '../components/Footer';
import ScrollAdd from '../components/ScrollAdd';
import Navbar from '../components/Navbar';

const PrivacyPolicy = () => {
  return (
    <>
      <ScrollAdd />
      <Navbar />
      
      <div className="privacy-policy-container">
        <h1 className="privacy-policy-title">Privacy Policy for Learnorama.in</h1>

        <div className="privacy-policy-content">
          {/* <h2 className="privacy-policy-subtitle">Effective Date: [Date]</h2> */}

          <div className="privacy-policy-section">
            <h3 className="privacy-policy-section-title">Overview</h3>
            <p>Welcome to Learnorama.in. Our Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website, subscribe to our newsletters, or engage with our news and educational services. Please read this Privacy Policy carefully to understand our practices.</p>
          </div>

          <div className="privacy-policy-section">
            <h3 className="privacy-policy-section-title">Information We Collect</h3>
            <ol>
              <li>
                <strong>Personal Information:</strong>
                <ul>
                  <li>We may collect personal information such as your name, email address, contact details, and any information necessary for account registration, event participation, or newsletter subscriptions.</li>
                </ul>
              </li>
              <li>
                <strong>Non-Personal Information:</strong>
                <ul>
                  <li>We may collect non-personal information such as browser type, operating system, and pages visited to enhance the user experience on our website and analyze trends in news content engagement.</li>
                </ul>
              </li>
            </ol>
          </div>

          <div className="privacy-policy-section">
            <h3 className="privacy-policy-section-title">How We Use Your Information</h3>
            <ol>
              <li>
                <strong>Service Provision:</strong>
                <ul>
                  <li>We use your personal information to manage your account, process event registrations, and provide access to news and educational services.</li>
                </ul>
              </li>
              <li>
                <strong>Communication:</strong>
                <ul>
                  <li>We may use your contact information to send updates, newsletters, news articles, and announcements relevant to your interests in education, learning technologies, and related topics.</li>
                </ul>
              </li>
              <li>
                <strong>Improving Services:</strong>
                <ul>
                  <li>Non-personal information may be used to analyze trends, improve website functionality, and enhance our news and educational offerings based on user preferences.</li>
                </ul>
              </li>
            </ol>
          </div>

          <div className="privacy-policy-section">
            <h3 className="privacy-policy-section-title">Information Sharing</h3>
            <ol>
              <li>
                <strong>Third-Party Service Providers:</strong>
                <ul>
                  <li>We may share necessary information with third-party service providers (e.g., email marketing platforms, hosting services) to deliver our news and educational content effectively.</li>
                </ul>
              </li>
              <li>
                <strong>Legal Requirements:</strong>
                <ul>
                  <li>We may disclose information if required by law or in response to legal processes.</li>
                </ul>
              </li>
            </ol>
          </div>

          <div className="privacy-policy-section">
            <h3 className="privacy-policy-section-title">News Services and Content Usage</h3>
            <p>By subscribing to our newsletters or accessing our news services, you consent to the collection of data related to your engagement with our news articles, including click behavior, content preferences, and reading patterns. This information helps us curate personalized content and improve our offerings. We do not share or sell your engagement data with advertisers or third parties for marketing purposes.</p>
          </div>

          <div className="privacy-policy-section">
            <h3 className="privacy-policy-section-title">Data Security</h3>
            <p>We implement reasonable security measures to protect your information. However, no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.</p>
          </div>

          <div className="privacy-policy-section">
            <h3 className="privacy-policy-section-title">Your Choices</h3>
            <p>You have the right to:</p>
            <ul>
              <li>Access, correct, or delete your personal information.</li>
              <li>Opt-out of receiving non-essential communications, including newsletters and promotional materials.</li>
            </ul>
          </div>

          <div className="privacy-policy-section">
            <h3 className="privacy-policy-section-title">Children's Privacy</h3>
            <p>Our services are not directed at individuals under the age of 13. We do not knowingly collect personal information from children under 13. If you believe we have inadvertently collected information from a child, please contact us immediately.</p>
          </div>

          <div className="privacy-policy-section">
            <h3 className="privacy-policy-section-title">Changes to this Privacy Policy</h3>
            <p>We reserve the right to update this Privacy Policy periodically. Changes will be effective upon posting on our website.</p>
          </div>

          <div className="privacy-policy-section">
            <h3 className="privacy-policy-section-title">Contact Us</h3>
            <p>For questions or concerns about our Privacy Policy, please contact us at <a href="mailto:info@learnorama.in">info@learnorama.in</a>.</p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
