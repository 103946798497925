import React from 'react'
import ScrollAdd from '../../components/ScrollAdd'
import Navbar from '../../components/Navbar'
import Slider from '../../components/slider/Slider'
import FactSection from '../../components/FactSection'
import Footer from '../../components/Footer'
import './BlogPost.css'; 

const Blogpg1 = () => {
  return (
    <>
    <ScrollAdd />
      <Navbar />
      <Slider />
      <FactSection />
      <div className="blog-post-container">
      <h1 className="blog-post-title">Manchu Nirvana: A Journey of Brilliance</h1>
      <img src="./images/manchu-bg1.jpeg" alt="Manchu Nirvana" className="blog-post-image" />
      <div className="blog-post-content">
        <p>Manchu Nirvana's journey to becoming the youngest chess trainer and creating a world record is a story of exceptional talent, dedication, and support. Born into a family with a rich legacy in the entertainment industry, Nirvana showed early signs of brilliance and a keen interest in the game of chess.</p>
        <p>At the age of 6, Nirvana made history by becoming the youngest chess trainer in the world, a feat that captured the attention of enthusiasts and admirers worldwide. Under the guidance of Dr. Padala Karthik Reddy, a renowned educator and mentor, Nirvana honed her skills and knowledge of the game, demonstrating remarkable insight and understanding beyond her years.</p>
        <p>The achievement was not only a testament to Nirvana's natural talent and intelligence but also a reflection of the unwavering support and encouragement she received from her family, particularly her grandfather, Dr. Manchu Mohan Babu. His pride and joy in Nirvana's accomplishment, along with his recognition of Dr. Padala Karthik Reddy's efforts, underscored the significance of mentorship and guidance in nurturing young talent.</p>
        <p>Nirvana's world record not only made headlines but also inspired countless aspiring chess players and young minds worldwide. Her journey serves as a reminder of the limitless potential that lies within every individual, regardless of age or background, and the importance of support and mentorship in unlocking that potential.</p>
        <p>As Nirvana continues to carve her path in the world of chess and beyond, her story stands as a shining example of determination, perseverance, and the power of believing in oneself. With her talent, passion, and the guidance of mentors like Dr. Padala Karthik Reddy, Nirvana is destined to leave a lasting legacy in the world of chess and inspire future generations to dream big and pursue their passions relentlessly.</p>
      </div>
    </div>

      <Footer />
    </>
  )
}

export default Blogpg1