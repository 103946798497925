import { Typography } from "@material-ui/core";
import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const Peopleadv = () => {
  return (
    <>
      <Navbar />

      {/* ------------------------------------------------------------------- */}

      <div className="heading-main-box" style={{ marginTop: "20px" }}>
        <h5 style={{ color: "#000" }}>Learnorama</h5>
        <h1>THE DISCOVERY ADVENTURES SUMMER CAMP 2024 </h1>
        <h1>BY PEOPLES SCHOOL OF EXCELLENCE</h1>
      </div>
      <div
        style={{
          margin: "auto",
          textAlign: "center",
          justifyContent: "center",
        }}>
        <img
          style={{ width: "50%", backgroundColor: "#fff",borderRadius:'15px' }}
          src="./images/Adventure camp.jpg"
          alt="About"
        />
      </div>

      <Typography
        style={{ width: "80%", margin: "auto", textAlign: "justify" }}>
        <p>
          Adventure: Outdoor treasure hunt where campers solve clues to find
          hidden treasure in the wilderness. Wednesday activity timings from
          4:30 pm to 9:00 pm.
        </p>
        <p>
          AI Introduction to coding workshop where campers learn to create
          simple AI-powered games or chatbots, and group music composition
          session where campers collaborate to write and perform original songs.
          Thursday activity timings from 3:00 pm to 8:00 pm.
        </p>
        <p>
          Educational Astronomy Night where campers stargaze and learn about
          constellations, and Outdoor Ecology Lesson where campers study local
          flora and fauna, learning about ecosystems and biodiversity. Friday
          activity timings from 3:00 pm to 9:00 pm.
        </p>
        <p>
          AI Introduction to machine learning workshop where campers explore
          basic concepts through interactive demonstrations and activities, and
          nature photography expedition where campers learn photography
          techniques while exploring the natural environment. Saturday activity
          timings from 3:00 pm to 9:00 pm.
        </p>
        <p>
          Adventure rock climbing and rappelling excursion at a nearby climbing
          site, and creative arts and crafts session where campers will learn
          how to create AI-powered music and beautiful art in nature. Sunday
          activity timings from 10:30 am to 8:00 pm.
        </p>
        <p>
          Survival skills challenge: Campers can learn essential survival skills
          like building a shelter, starting a fire without matche s, identifying
          edible plants, and navigating using natural landmarks. Tuesday timings
          from 3:00 pm to 8:00 pm.
        </p>

        <h3>Notes:</h3>
        <p>
          This camp is designed for high schoolers aged 10 to 16 years, spanning
          14 days with 5 days of outdoor activities, 8 days of indoor
          activities, and a closing ceremony. The venue for this camp is
          People's High School, Himayath Nagar, Hyderabad, the Vintage schools
          with over 40 years of educational background experience. It is now
          partnering with Learnorama to integrate new technologies and
          adventures for the present generation.
        </p>
      </Typography>

      <Footer />
    </>
  );
};

export default Peopleadv;
