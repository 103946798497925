import React, { useState } from "react";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";

const chatbotQuestions = [
  {
    id: 1,
    text: "What is the best career plan for children?",
    explanation:
      "Choosing the right career plan involves understanding the child’s interests, strengths, and long-term goals.",
    goals: [
      {
        id: 1,
        text: "Identify Interests",
        answer: "Understand what the child is passionate about.",
      },
      {
        id: 2,
        text: "Assess Strengths",
        answer: "Evaluate the child’s skills and strengths.",
      },
      {
        id: 3,
        text: "Set Long-term Goals",
        answer:
          "Define what career goals align with their interests and strengths.",
      },
    ],
  },
  {
    id: 2,
    text: "How can Learnorama make education easy for my child?",
    explanation:
      "Learnorama provides interactive and personalized learning experiences tailored to each child’s needs.",
    goals: [
      {
        id: 1,
        text: "Interactive Learning",
        answer: "Engaging content that keeps children interested.",
      },
      {
        id: 2,
        text: "Personalized Experience",
        answer: "Custom-tailored learning plans for each student.",
      },
      {
        id: 3,
        text: "Progress Tracking",
        answer: "Tools to monitor and track educational progress.",
      },
    ],
  },
  {
    id: 3,
    text: "What are the benefits of joining Learnorama's adventure camps?",
    explanation:
      "Our adventure camps teach life skills, survival skills, risk and financial management skills, and money management skills.",
    goals: [
      {
        id: 1,
        text: "Developing Practical Life Skills",
        answer:
          "Building shelters, cooking meals, and administering basic first aid.",
      },
      {
        id: 2,
        text: "Enhancing Survival Skills",
        answer:
          "Starting fires without matches, purifying water, and identifying edible plants.",
      },
      {
        id: 3,
        text: "Learning Risk and Financial Management Skills",
        answer: "Understanding investment basics and planning budgets.",
      },
      {
        id: 4,
        text: "Acquiring Money Management Skills",
        answer: "Budgeting, saving, and understanding credit and debt.",
      },
    ],
  },
  {
    id: 4,
    text: "How does Learnorama's AI course for children work?",
    explanation:
      "The course consists of five videos covering various topics, and costs just 555/- rupees. Students who qualify the online live exam will receive a Level 1 Artificial Intelligence completion certificate.",
    goals: [
      {
        id: 1,
        text: "Understanding the Basics of AI",
        answer:
          "Learning what AI is, machine learning concepts, and experimenting with AI tools.",
      },
      {
        id: 2,
        text: "Applying AI Concepts in Real-Life Scenarios",
        answer: "Developing AI-driven chatbots and creating predictive models.",
      },
      {
        id: 3,
        text: "Earning a Recognized Certificate in AI",
        answer:
          "Enhancing academic and job applications with an AI certification.",
      },
      {
        id: 4,
        text: "Enhancing Problem-Solving and Analytical Skills",
        answer:
          "Breaking down problems, experimenting with algorithms, and collaborating on AI projects.",
      },
    ],
  },
  {
    id: 5,
    text: "What kind of projects do students work on at Learnorama?",
    explanation:
      "Students work on innovative projects like creating websites, AI-based products, and educational videos.",
    goals: [
      {
        id: 1,
        text: "Gaining Hands-On Experience in AI and Web Development",
        answer:
          "Developing personal websites and AI-driven mobile applications.",
      },
      {
        id: 2,
        text: "Developing Creativity and Innovation",
        answer:
          "Brainstorming new ideas and innovating solutions for problems.",
      },
      {
        id: 3,
        text: "Learning Project Management Skills",
        answer: "Planning projects, managing resources, and tracking progress.",
      },
      {
        id: 4,
        text: "Building a Strong Portfolio for Future Opportunities",
        answer:
          "Showcasing projects and preparing for college and job applications.",
      },
    ],
  },
  {
    id: 6,
    text: "How does Learnorama support students' entrepreneurial skills?",
    explanation:
      "We offer courses and camps that teach students how to create and run their own businesses.",
    goals: [
      {
        id: 1,
        text: "Learning Business Model Creation",
        answer: "Developing value propositions and creating revenue models.",
      },
      {
        id: 2,
        text: "Understanding Marketing and Sales Strategies",
        answer:
          "Conducting market research and developing marketing campaigns.",
      },
      {
        id: 3,
        text: "Developing Leadership and Management Skills",
        answer: "Leading team projects and managing resources.",
      },
      {
        id: 4,
        text: "Launching Their Own Successful Businesses",
        answer: "Creating pitch presentations and securing funding.",
      },
    ],
  },
  {
    id: 7,
    text: "What are the key features of Learnorama's AI tools?",
    explanation:
      "Our AI tools are designed to be user-friendly and affordable, with a cost of just 1 to 5 rupees.",
    goals: [
      {
        id: 1,
        text: "Facilitating Easy Access to AI Technology",
        answer: "Providing intuitive interfaces and online access.",
      },
      {
        id: 2,
        text: "Encouraging Widespread Use of AI Tools",
        answer: "Promoting AI literacy and organizing workshops.",
      },
      {
        id: 3,
        text: "Enabling Students to Create Innovative Solutions",
        answer: "Developing AI-driven apps and designing user experiences.",
      },
      {
        id: 4,
        text: "Providing a Cost-Effective Way to Learn and Use AI",
        answer: "Offering affordable plans and discounts.",
      },
    ],
  },
  {
    id: 8,
    text: "How can Learnorama's courses help improve academic performance?",
    explanation:
      "Our video classes cover all lessons and subjects, making it easier for students to understand and retain information.",
    goals: [
      {
        id: 1,
        text: "Enhancing Memory Retention Through Visual Learning",
        answer: "Watching educational animations and interactive videos.",
      },
      {
        id: 2,
        text: "Improving Exam Performance",
        answer:
          "Accessing past papers, engaging in mock exams, and getting personalized feedback.",
      },
      {
        id: 3,
        text: "Increasing Overall Knowledge and Understanding",
        answer:
          "Exploring additional resources and participating in discussion forums.",
      },
      {
        id: 4,
        text: "Providing Comprehensive Coverage of the Syllabus",
        answer:
          "Accessing full-course libraries and aligning with school textbooks.",
      },
    ],
  },
  {
    id: 9,
    text: "What awards and recognitions has Learnorama received?",
    explanation:
      "Learnorama has achieved world records, state, and national awards for its innovative programs and student achievements.",
    goals: [
      {
        id: 1,
        text: "Gaining Recognition for Excellence in Education",
        answer:
          "Receiving awards for best educational platform and innovative teaching methods.",
      },
      {
        id: 2,
        text: "Motivating Students to Strive for High Achievements",
        answer: "Offering scholarships and celebrating top performers.",
      },
      {
        id: 3,
        text: "Building Credibility and Trust in Our Programs",
        answer: "Showcasing testimonials and highlighting partnerships.",
      },
      {
        id: 4,
        text: "Showcasing Our Commitment to Quality Education",
        answer:
          "Investing in educator development and implementing student feedback.",
      },
    ],
  },
  {
    id: 10,
    text: "How can Learnorama help children develop life skills?",
    explanation:
      "Our programs include adventure camps and online classes focused on life skills, survival skills, and more.",
    goals: [
      {
        id: 1,
        text: "Preparing Children for Real-World Challenges",
        answer:
          "Teaching financial literacy, time management, and effective communication.",
      },
      {
        id: 2,
        text: "Teaching Practical and Essential Life Skills",
        answer:
          "Conducting workshops on cooking, safety, and digital literacy.",
      },
      {
        id: 3,
        text: "Enhancing Problem-Solving and Critical Thinking Abilities",
        answer:
          "Engaging in project-based learning and problem-solving activities.",
      },
      {
        id: 4,
        text: "Encouraging Independence and Self-Reliance",
        answer: "Offering solo projects and self-study techniques.",
      },
    ],
  },
  {
    id: 11,
    text: "What makes Learnorama's video classes unique?",
    explanation:
      "Our video classes are interactive, engaging, and cover a wide range of subjects and topics.",
    goals: [
      {
        id: 1,
        text: "Providing an Immersive Learning Experience",
        answer: "Using virtual reality and interactive elements.",
      },
      {
        id: 2,
        text: "Keeping Students Engaged and Interested",
        answer: "Featuring dynamic instructors and gamified modules.",
      },
      {
        id: 3,
        text: "Ensuring Thorough Understanding of Subjects",
        answer: "Breaking down complex topics and providing practice quizzes.",
      },
      {
        id: 4,
        text: "Offering Flexible Learning Options",
        answer:
          "Allowing access anytime, offline learning, and different learning paths.",
      },
    ],
  },
  {
    id: 12,
    text: "How does Learnorama integrate AI in its educational programs?",
    explanation:
      "We use AI to create personalized learning experiences, automate tasks, and develop innovative projects.",
    goals: [
      {
        id: 1,
        text: "Enhancing Personalized Learning",
        answer: "Using AI to provide tailored recommendations and feedback.",
      },
      {
        id: 2,
        text: "Increasing Efficiency Through Automation",
        answer: "Automating administrative tasks and grading.",
      },
      {
        id: 3,
        text: "Encouraging Creativity and Innovation",
        answer: "Developing AI-driven projects and interactive tools.",
      },
      {
        id: 4,
        text: "Tracking Progress and Providing Insights",
        answer: "Analyzing data to offer insights and track improvements.",
      },
    ],
  },
  {
    id: 13,
    text: "What opportunities does Learnorama provide for hands-on learning?",
    explanation:
      "Our programs include practical projects, real-world applications, and interactive learning experiences.",
    goals: [
      {
        id: "A",
        text: "Gaining practical skills and experience",
        subgoals: [
          "Participating in lab experiments",
          "Engaging in real-world problem-solving",
          "Completing practical assignments",
          "Working on industry-based projects",
          "Developing technical skills through hands-on practice",
        ],
      },
      {
        id: "B",
        text: "Applying theoretical knowledge in real-life scenarios",
        subgoals: [
          "Conducting field research projects",
          "Engaging in case study analysis",
          "Participating in internships and apprenticeships",
          "Working on community service projects",
          "Applying classroom knowledge to personal projects",
        ],
      },
      {
        id: "C",
        text: "Developing problem-solving abilities",
        subgoals: [
          "Working on collaborative projects",
          "Participating in coding and robotics challenges",
          "Engaging in design thinking workshops",
          "Solving real-world business problems",
          "Participating in hackathons and competitions",
        ],
      },
      {
        id: "D",
        text: "Building a strong practical foundation",
        subgoals: [
          "Completing certification programs",
          "Engaging in hands-on learning modules",
          "Building portfolios of completed projects",
          "Participating in practical exams and assessments",
          "Gaining experience through practical simulations",
        ],
      },
    ],
  },
  {
    id: 14,
    text: "How does Learnorama support continuous learning?",
    explanation:
      "We offer a variety of courses, workshops, and camps that cater to different learning needs and interests.",
    goals: [
      {
        id: "A",
        text: "Encouraging lifelong learning",
        subgoals: [
          "Offering courses for all age groups",
          "Providing access to a wide range of subjects",
          "Supporting professional development",
          "Encouraging hobbies and personal interests",
          "Offering refresher courses and updates",
        ],
      },
      {
        id: "B",
        text: "Providing diverse learning opportunities",
        subgoals: [
          "Offering online and offline learning options",
          "Providing short-term and long-term courses",
          "Offering workshops and boot camps",
          "Supporting self-paced learning",
          "Providing group learning experiences",
        ],
      },
      {
        id: "C",
        text: "Supporting personal and professional growth",
        subgoals: [
          "Offering career counseling and guidance",
          "Providing leadership and management courses",
          "Offering courses on personal development",
          "Providing skill development workshops",
          "Supporting continuous improvement",
        ],
      },
      {
        id: "D",
        text: "Ensuring accessibility to learning resources",
        subgoals: [
          "Providing 24/7 access to online materials",
          "Offering downloadable resources",
          "Providing mobile-friendly content",
          "Offering offline study options",
          "Ensuring inclusive education for all",
        ],
      },
    ],
  },
  {
    id: 15,
    text: "What kind of educational programs does Learnorama offer for schools?",
    explanation:
      "We collaborate with schools to provide specialized programs, including summer camps, Olympiads, and academic expos.",
    goals: [
      {
        id: "A",
        text: "Enhancing school curriculum with innovative programs",
        subgoals: [
          "Offering supplementary courses in emerging fields",
          "Providing experiential learning opportunities",
          "Integrating AI and technology in education",
          "Supporting STEM education initiatives",
          "Offering arts and cultural programs",
        ],
      },
      {
        id: "B",
        text: "Encouraging student participation in competitive events",
        subgoals: [
          "Organizing academic Olympiads",
          "Hosting science and technology fairs",
          "Conducting sports and cultural competitions",
          "Offering online challenges and quizzes",
          "Providing opportunities for international competitions",
        ],
      },
      {
        id: "C",
        text: "Supporting teacher development and training",
        subgoals: [
          "Offering professional development workshops",
          "Providing training in new teaching methodologies",
          "Supporting the use of technology in classrooms",
          "Offering resources for curriculum development",
          "Providing mentoring and coaching for teachers",
        ],
      },
      {
        id: "D",
        text: "Promoting holistic education",
        subgoals: [
          "Offering programs in life skills and personal development",
          "Supporting mental health and well-being initiatives",
          "Providing career guidance and counseling",
          "Encouraging community service and volunteerism",
          "Supporting environmental education and sustainability",
        ],
      },
    ],
  },
  {
    id: 16,
    text: "How can Learnorama's motivational videos benefit students?",
    explanation:
      "Our motivational videos aim to inspire students to achieve their goals and overcome challenges.",
    goals: [
      {
        id: "A",
        text: "Boosting student confidence and self-esteem",
        subgoals: [
          "Sharing success stories of peers",
          "Highlighting the importance of perseverance",
          "Offering tips for overcoming academic challenges",
          "Encouraging positive self-talk and mindset",
          "Providing strategies for building resilience",
        ],
      },
      {
        id: "B",
        text: "Encouraging goal setting and achievement",
        subgoals: [
          "Offering guidance on setting SMART goals",
          "Sharing techniques for effective planning",
          "Providing examples of goal-oriented success",
          "Encouraging regular progress tracking",
          "Celebrating milestones and achievements",
        ],
      },
      {
        id: "C",
        text: "Promoting a growth mindset",
        subgoals: [
          "Explaining the benefits of lifelong learning",
          "Encouraging the acceptance of failures as learning opportunities",
          "Providing strategies for continuous self-improvement",
          "Highlighting the importance of adaptability",
          "Sharing stories of personal growth and transformation",
        ],
      },
      {
        id: "D",
        text: "Fostering a positive attitude towards learning",
        subgoals: [
          "Sharing the joy and benefits of learning",
          "Highlighting interesting and fun learning experiences",
          "Encouraging curiosity and exploration",
          "Providing tips for maintaining motivation",
          "Offering support and encouragement through tough times",
        ],
      },
    ],
  },
  {
    id: 17,
    text: "How does Learnorama's AI image generation help students?",
    explanation:
      "Our AI tools help students create and manipulate images, enhancing their creativity and technical skills.",
    goals: [
      {
        id: "A",
        text: "Enhancing creative skills",
        subgoals: [
          "Offering tools for digital art and design",
          "Providing tutorials on using AI for creative projects",
          "Encouraging exploration of new artistic styles",
          "Supporting the development of unique visual content",
          "Offering platforms for showcasing student art",
        ],
      },
      {
        id: "B",
        text: "Improving technical proficiency",
        subgoals: [
          "Teaching the basics of AI and image generation",
          "Offering hands-on projects with AI tools",
          "Providing resources for learning advanced techniques",
          "Encouraging experimentation and innovation",
          "Supporting the integration of AI in other subjects",
        ],
      },
      {
        id: "C",
        text: "Facilitating collaborative projects",
        subgoals: [
          "Offering platforms for group projects",
          "Encouraging peer feedback and collaboration",
          "Providing tools for sharing and editing images",
          "Supporting joint creative endeavors",
          "Encouraging participation in collaborative art challenges",
        ],
      },
      {
        id: "D",
        text: "Supporting diverse educational applications",
        subgoals: [
          "Using AI images in science and geography projects",
          "Enhancing presentations and reports with visual aids",
          "Supporting storytelling and creative writing projects",
          "Providing visual content for multimedia projects",
          "Offering tools for creating educational materials",
        ],
      },
    ],
  },
  {
    id: 18,
    text: "How can students use Learnorama's online courses to develop AI skills?",
    explanation:
      "Our online courses cover various aspects of AI, from basic concepts to advanced applications, helping students build valuable skills.",
    goals: [
      {
        id: "A",
        text: "Building foundational knowledge in AI",
        subgoals: [
          "Offering introductory courses on AI concepts",
          "Providing tutorials on AI tools and software",
          "Explaining the history and evolution of AI",
          "Teaching basic programming and algorithms",
          "Providing resources for self-study and exploration",
        ],
      },
      {
        id: "B",
        text: "Developing practical AI skills",
        subgoals: [
          "Offering hands-on projects and exercises",
          "Providing real-world applications of AI",
          "Encouraging the creation of AI-driven projects",
          "Offering coding challenges and hackathons",
          "Providing mentorship and support from AI experts",
        ],
      },
      {
        id: "C",
        text: "Encouraging innovation and creativity in AI",
        subgoals: [
          "Offering courses on AI for creative applications",
          "Supporting the development of innovative AI solutions",
          "Encouraging participation in AI competitions",
          "Providing platforms for showcasing AI projects",
          "Offering feedback and guidance on AI innovations",
        ],
      },
      {
        id: "D",
        text: "Preparing for advanced studies and careers in AI",
        subgoals: [
          "Offering advanced courses on AI and machine learning",
          "Providing career guidance and counseling",
          "Supporting internship and research opportunities",
          "Offering resources for further study and specialization",
          "Connecting students with AI professionals and networks",
        ],
      },
    ],
  },
  {
    id: 19,
    text: "How does Learnorama ensure the quality of its educational content?",
    explanation:
      "We prioritize quality by collaborating with experts, continuously updating our materials, and incorporating feedback from educators and students.",
    goals: [
      {
        id: "A",
        text: "Collaborating with subject matter experts",
        subgoals: [
          "Working with experienced educators and professionals",
          "Consulting industry experts for specialized content",
          "Incorporating feedback from academic advisors",
          "Reviewing content for accuracy and relevance",
          "Ensuring alignment with educational standards",
        ],
      },
      {
        id: "B",
        text: "Regularly updating and improving content",
        subgoals: [
          "Conducting periodic reviews of course materials",
          "Incorporating the latest research and developments",
          "Updating resources based on student feedback",
          "Enhancing content with new technologies",
          "Providing continuous training for content creators",
        ],
      },
      {
        id: "C",
        text: "Ensuring interactive and engaging learning experiences",
        subgoals: [
          "Including multimedia elements in lessons",
          "Offering interactive quizzes and activities",
          "Encouraging student participation and interaction",
          "Providing hands-on projects and experiments",
          "Offering real-world applications and examples",
        ],
      },
      {
        id: "D",
        text: "Maintaining high standards of delivery",
        subgoals: [
          "Training instructors in effective teaching methods",
          "Using high-quality production techniques for videos",
          "Ensuring clear and concise presentation of content",
          "Providing support and resources for students",
          "Monitoring and evaluating the effectiveness of courses",
        ],
      },
    ],
  },
  {
    id: 20,
    text: "What are Learnorama's plans for future development?",
    explanation:
      "We have several key areas of focus for future development to enhance our educational offerings and impact.",
    goals: [
      {
        id: "A",
        text: "Expanding educational content and resources",
        subgoals: [
          "Developing new courses and programs",
          "Expanding into new subject areas",
          "Incorporating the latest research and technologies",
          "Creating partnerships with industry experts",
          "Enhancing content delivery methods",
        ],
      },
      {
        id: "B",
        text: "Enhancing platform features and user experience",
        subgoals: [
          "Improving website and app usability",
          "Adding new interactive features",
          "Enhancing mobile and offline access",
          "Optimizing performance and speed",
          "Gathering and implementing user feedback",
        ],
      },
      {
        id: "C",
        text: "Improving accessibility and inclusivity",
        subgoals: [
          "Providing courses for students with special needs",
          "Offering content in multiple languages",
          "Ensuring mobile-friendly and offline access",
          "Creating affordable and free resources",
          "Partnering with organizations to reach underserved communities",
        ],
      },
      {
        id: "D",
        text: "Enhancing student and teacher engagement",
        subgoals: [
          "Developing more interactive and gamified content",
          "Offering live sessions and webinars",
          "Providing personalized learning paths",
          "Encouraging community building and peer support",
          "Offering continuous professional development for teachers",
        ],
      },
    ],
  },
  {
    id: 21,
    text: "How does Learnorama collaborate with educational institutions?",
    explanation:
      "We partner with schools, colleges, and universities to enhance their educational offerings with our resources and expertise.",
    goals: [
      {
        id: "A",
        text: "Supporting curriculum enhancement",
        subgoals: [
          "Offering supplementary courses and materials",
          "Providing resources for project-based learning",
          "Supporting the integration of technology in classrooms",
          "Offering specialized programs like AI and coding",
          "Providing access to cutting-edge research and developments",
        ],
      },
      {
        id: "B",
        text: "Facilitating teacher training and development",
        subgoals: [
          "Offering professional development workshops",
          "Providing training in new teaching methodologies",
          "Supporting the use of innovative educational tools",
          "Offering mentorship and coaching programs",
          "Providing resources for continuous improvement",
        ],
      },
      {
        id: "C",
        text: "Encouraging student engagement and participation",
        subgoals: [
          "Organizing competitions and challenges",
          "Offering internship and research opportunities",
          "Providing platforms for showcasing student work",
          "Supporting extracurricular activities and clubs",
          "Offering career counseling and guidance",
        ],
      },
      {
        id: "D",
        text: "Promoting community involvement and collaboration",
        subgoals: [
          "Partnering with local organizations for community projects",
          "Encouraging volunteerism and service learning",
          "Supporting parent and community engagement",
          "Offering community workshops and events",
          "Facilitating collaboration between institutions",
        ],
      },
    ],
  },
  {
    id: 22,
    text: "How does Learnorama integrate technology into education?",
    explanation:
      "We utilize the latest technologies to create interactive, engaging, and effective learning experiences.",
    goals: [
      {
        id: "A",
        text: "Using digital tools to enhance learning",
        subgoals: [
          "Offering online courses and resources",
          "Providing interactive multimedia content",
          "Supporting virtual and augmented reality experiences",
          "Utilizing AI for personalized learning",
          "Offering data analytics for tracking progress",
        ],
      },
      {
        id: "B",
        text: "Supporting blended and hybrid learning models",
        subgoals: [
          "Combining online and offline learning experiences",
          "Offering flexible learning schedules",
          "Providing resources for flipped classrooms",
          "Supporting remote and distance learning",
          "Facilitating collaborative online projects",
        ],
      },
      {
        id: "C",
        text: "Encouraging the use of innovative educational technologies",
        subgoals: [
          "Offering training on the latest edtech tools",
          "Providing access to cutting-edge software and platforms",
          "Encouraging experimentation and innovation",
          "Supporting the development of edtech solutions",
          "Collaborating with tech companies for resource development",
        ],
      },
      {
        id: "D",
        text: "Ensuring security and privacy in digital learning",
        subgoals: [
          "Implementing robust cybersecurity measures",
          "Protecting student data and privacy",
          "Providing secure access to online resources",
          "Educating users on digital safety",
          "Complying with data protection regulations",
        ],
      },
    ],
  },
  {
    id: 23,
    text: "What impact does Learnorama aim to have on students' future careers?",
    explanation:
      "We aim to equip students with the skills and knowledge needed to succeed in their chosen careers.",
    goals: [
      {
        id: "A",
        text: "Providing career-oriented education",
        subgoals: [
          "Offering courses aligned with industry needs",
          "Providing practical skills and knowledge",
          "Encouraging participation in internships and apprenticeships",
          "Offering career counseling and guidance",
          "Supporting continuous professional development",
        ],
      },
      {
        id: "B",
        text: "Encouraging entrepreneurship and innovation",
        subgoals: [
          "Offering courses on entrepreneurship and business skills",
          "Supporting student-led projects and startups",
          "Providing resources for innovation and creativity",
          "Encouraging participation in entrepreneurial competitions",
          "Offering mentorship and networking opportunities",
        ],
      },
      {
        id: "C",
        text: "Preparing students for the future workforce",
        subgoals: [
          "Teaching emerging technologies and trends",
          "Providing training in soft skills and leadership",
          "Offering resources for job readiness",
          "Encouraging adaptability and continuous learning",
          "Supporting professional networking and connections",
        ],
      },
      {
        id: "D",
        text: "Promoting lifelong learning and growth",
        subgoals: [
          "Offering resources for ongoing education and development",
          "Encouraging a growth mindset and adaptability",
          "Providing access to advanced and specialized courses",
          "Supporting career transitions and upskilling",
          "Promoting personal and professional fulfillment",
        ],
      },
    ],
  },
  {
    id: 24,
    text: "How does Learnorama measure the success of its educational programs?",
    explanation:
      "We use various metrics and feedback mechanisms to assess and improve the effectiveness of our programs.",
    goals: [
      {
        id: "A",
        text: "Tracking student progress and outcomes",
        subgoals: [
          "Monitoring academic performance and achievements",
          "Collecting data on student engagement and participation",
          "Tracking completion rates and certification",
          "Assessing the impact on career and personal growth",
          "Providing regular progress reports",
        ],
      },
      {
        id: "B",
        text: "Gathering feedback from students and educators",
        subgoals: [
          "Conducting surveys and evaluations",
          "Collecting testimonials and case studies",
          "Holding focus groups and feedback sessions",
          "Encouraging continuous dialogue and suggestions",
          "Implementing changes based on feedback",
        ],
      },
      {
        id: "C",
        text: "Evaluating program effectiveness and quality",
        subgoals: [
          "Conducting regular reviews and assessments",
          "Comparing outcomes with set goals and benchmarks",
          "Using data analytics for deeper insights",
          "Assessing the relevance and accuracy of content",
          "Ensuring continuous improvement and innovation",
        ],
      },
      {
        id: "D",
        text: "Benchmarking against industry standards",
        subgoals: [
          "Comparing programs with national and international standards",
          "Participating in external evaluations and accreditations",
          "Engaging with educational research and trends",
          "Collaborating with other institutions for benchmarking",
          "Adopting best practices from leading educators",
        ],
      },
    ],
  },
  {
    id: 25,
    text: "What are Learnorama's key values and principles?",
    explanation:
      "Our values guide our mission to provide high-quality, accessible education and foster a supportive learning environment.",
    goals: [
      {
        id: "A",
        text: "Commitment to excellence",
        subgoals: [
          "Providing high-quality educational content",
          "Ensuring continuous improvement and innovation",
          "Upholding high standards of teaching and delivery",
          "Encouraging academic and professional excellence",
          "Striving for the best outcomes for students",
        ],
      },
      {
        id: "B",
        text: "Inclusivity and accessibility",
        subgoals: [
          "Ensuring education is accessible to all",
          "Providing resources for diverse learning needs",
          "Promoting equal opportunities for all students",
          "Supporting inclusive learning environments",
          "Offering affordable and free resources",
        ],
      },
      {
        id: "C",
        text: "Innovation and creativity",
        subgoals: [
          "Encouraging creative thinking and problem-solving",
          "Supporting the use of innovative technologies",
          "Promoting experimentation and innovation",
          "Fostering a culture of continuous improvement",
          "Encouraging student-led projects and ideas",
        ],
      },
      {
        id: "D",
        text: "Lifelong learning and growth",
        subgoals: [
          "Promoting a growth mindset and adaptability",
          "Supporting continuous education and development",
          "Encouraging personal and professional fulfillment",
          "Providing resources for lifelong learning",
          "Encouraging exploration and curiosity",
        ],
      },
      {
        id: "E",
        text: "Community and collaboration",
        subgoals: [
          "Fostering a supportive learning community",
          "Encouraging collaboration and peer support",
          "Engaging with local and global communities",
          "Promoting partnerships and collaborations",
          "Supporting community involvement and service",
        ],
      },
    ],
  },
];

const Chatbot = ({ isOpen, onClose }) => {
  const [activeQuestionId, setActiveQuestionId] = useState(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [synth] = useState(window.speechSynthesis);

  const handleQuestionClick = (questionId) => {
    setActiveQuestionId((prevId) =>
      prevId === questionId ? null : questionId
    );
  };

  const speakText = (text) => {
    if (!synth) return;
    const utterance = new SpeechSynthesisUtterance(text);
    synth.speak(utterance);
    setIsSpeaking(true);

    utterance.onend = () => setIsSpeaking(false);
  };

  const handleSpeakClick = (text) => {
    speakText(text);
  };

  const handleMuteClick = () => {
    synth.cancel();
    setIsSpeaking(false);
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 16,
        right: 16,
        width: 300,
        backgroundColor: "#fff",
        boxShadow: 3,
        borderRadius: 2,
        display: isOpen ? "flex" : "none",
        flexDirection: "column",
        height: 400,
        zIndex: 999, // Ensures the chatbot is on top of other elements
      }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: 1,
          borderBottom: "1px solid #ddd",
          background: "rgb(0,15,38)",
          background: "linear-gradient(137deg, rgb(241, 115, 30) 18%, rgb(231, 29, 54) 95%)",
        }}>
        <Box sx={{ flexGrow: 1, fontWeight: "bold" }}>Chatbot</Box>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ flexGrow: 1, overflowY: "auto", padding: 2 }}>
        <p>
          Welcome to Learnorama!
          <br />
          How can I assist you today? Please select one of the following
          questions:
        </p>
        {!activeQuestionId ? (
          <List>
            {chatbotQuestions.map((question) => (
              <ListItem
                button
                key={question.id}
                onClick={() => handleQuestionClick(question.id)}>
                <ListItemText
                  primary={
                    <Typography variant="body1" color="primary">
                      {question.text}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Box>
            {chatbotQuestions
              .filter((question) => question.id === activeQuestionId)
              .map((question) => (
                <Box key={question.id}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {question.text}
                  </Typography>
                  <Typography variant="body2" sx={{ marginBottom: 2 }}>
                    {question.explanation}
                  </Typography>
                  <List>
                    {question.goals.map((goal) => (
                      <ListItem button key={goal.id}>
                        <ListItemText
                          primary={goal.text}
                          secondary={goal.answer}
                          onClick={() =>
                            handleSpeakClick(`${goal.text}: ${goal.answer}`)
                          }
                        />
                        <IconButton
                          sx={{
                            marginLeft: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            width: "3px",
                          }}
                          onClick={() => handleSpeakClick(question.explanation)}
                          disabled={isSpeaking}>
                          <VolumeUpIcon />
                        </IconButton>
                        <IconButton
                          sx={{
                            marginLeft: 1.5,
                            display: "flex",
                            justifyContent: "space-between",
                            width: "3px",
                          }}
                          onClick={handleMuteClick}
                          disabled={!isSpeaking}>
                          <VolumeOffIcon />
                        </IconButton>
                      </ListItem>
                    ))}
                  </List>
                  <Box sx={{ marginTop: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleQuestionClick(null)}>
                      Close
                    </Button>
                    <IconButton
                      sx={{ marginLeft: 1 }}
                      onClick={() => handleSpeakClick(question.explanation)}
                      disabled={isSpeaking}>
                      <VolumeUpIcon />
                    </IconButton>
                    <IconButton
                      sx={{ marginLeft: 1 }}
                      onClick={handleMuteClick}
                      disabled={!isSpeaking}>
                      <VolumeOffIcon />
                    </IconButton>
                  </Box>
                </Box>
              ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Chatbot;
