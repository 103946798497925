import React from "react";
import Footer from "../components/Footer";
import ScrollAdd from "../components/ScrollAdd";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
// import FloatingEnrollButton from "../components/floatingenrollButton/FloatingEnrollButton";
import TextToSpeechVideoComponent from "../online-classes/Ai-learnorama/TextToSpeechVideoComponent";

const Pages = () => {
  return (
    <>
      <ScrollAdd />
      <Navbar />

      {/* <FloatingEnrollButton /> */}

      <div className="pages-box1">
        <h2>Short Link</h2>
        {/* <Link style={{textDecoration:'none',color:'#000',margin:'auto',textAlign:'center'}} to="/about"><p className='button-pg'>About Us</p></Link> */}

        {/* <Link style={{textDecoration:'none',color:'#000',margin:'auto',textAlign:'center'}} to="/contact"><p className='button-pg'>Contact Us</p></Link> */}

        <Link
          style={{
            textDecoration: "none",
            color: "#000",
            margin: "auto",
            textAlign: "center",
          }}
          to="/blog">
          <p className="button-pg">Latest Blog</p>
        </Link>

        <Link
          style={{
            textDecoration: "none",
            color: "#000",
            margin: "auto",
            textAlign: "center",
          }}
          to="/enrollment">
          <p className="button-pg">Enrollment</p>
        </Link>

        <Link
          style={{
            textDecoration: "none",
            color: "#000",
            margin: "auto",
            textAlign: "center",
          }}
          to="/winterquestcamp">
          <p className="button-pg">Up Comming Events</p>
        </Link>

        <Link
          style={{
            textDecoration: "none",
            color: "#000",
            margin: "auto",
            textAlign: "center",
          }}
          to="/offlinecamps">
          <p className="button-pg">Summer Camp</p>
        </Link>


        <Link
          style={{
            textDecoration: "none",
            color: "#000",
            margin: "auto",
            textAlign: "center",
          }}
          to="/nutritionforkids">
          <p className="button-pg">Nutrition For Kids</p>
        </Link>

        

        

        <a
          style={{
            textDecoration: "none",
            color: "#000",
            margin: "auto",
            textAlign: "center",
          }}
          href="https://maps.app.goo.gl/hZrvDSYaoQzwdDDC7">
          <p className="button-pg">Testimonials</p>
        </a>

        {/* <Link style={{textDecoration:'none',color:'#000',margin:'auto',textAlign:'center'}} to="/login"><p className='button-pg'>Login/Registration</p></Link> */}
      </div>

      <Footer />
    </>
  );
};

export default Pages;
