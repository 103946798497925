import React from 'react'
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

const Rhythmic = () => {
  return (
    <>
    <Navbar />

{/* ------------------------------------------------------------------- */}

<div className="heading-main-box" style={{marginTop:'20px'}}>
     <h5 style={{ color: "#000" }}>Learnorama</h5>
     <h1>RHYTHMIC BEATS SUMMER CAMP For Kids 2024 </h1>
     <h1>At MERIDIAN SCHOOL (UPPAL)</h1>
     
   </div>

   <div
        style={{
          margin: "auto",
          textAlign: "center",
          justifyContent: "center",
        }}>
        <img
          style={{ width: "50%", backgroundColor: "#fff",borderRadius:'15px' }}
          src="./images/DACE.jpg"
          alt="About"
        />
      </div>

   <div className='rythmic-container'>
      <div className="heading-main-box">
        <h1>Join Us for an Unforgettable Summer Camp Experience!</h1>
     
      <p>For Kids 1st to 9th Class Students</p>
      <p>Dates: April 29th to May 29th 2024</p>
      <p>Location: Meridian School, Uppal.</p>
      </div>
      <div>
      <h3>Activities Include</h3>
        <p>Dance: Western, Freestyle, Break Dance, Contemporary</p>
        <p>Music: Semi-Classical, Movie Style</p>
        <p>Music Production: Beat Creation, Music Forming DJ Sessions</p>
      </div>
      
      <div>
      <h3>Special Features</h3>
        <p>Learn from Super Singer Saregamapa Runner Up, Tollywood Singer Aditya</p>
        <p>Dance with Tollywood Choreographer Basker Nathan</p>
        <p>2 Guest Lectures by Renowned Music Directors</p>
      </div>
      <div>
      <h3>Special Prizes for Toppers:</h3>
      <p>5 to 10 Years Category: One Dance Video Album, One Song Recording Opportunity</p>
      <p>11 to 14 Years Category: One Dance Video Album, One Song Recording Opportunity</p>
      </div>

      <div>
      <h3>Entry fee:</h3>
      <p>2499/- for Meridian students</p>
      <p>2999/- for outside students</p>
      <p>Early bird Discount: 300/- before 31st March</p>

      </div>

      <div>
      <h3>Schedule:</h3>
      <p>Monday to Friday: 10.00 am to 3.00 pm</p>
      <p>Saturday & Sunday: Holiday</p>
      <p>Don't Miss Your Chance to Shine in the Dance and Music Industry!</p>
      <p>Transport facility available.</p>
      <p>Contact: 9849856143, 9949567143, 9959945143.</p>
      </div>
    </div>

    <Footer />
    </>
  )
}

export default Rhythmic;