import React, { useState } from 'react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, TypingIndicator } from '@chatscope/chat-ui-kit-react';
import Navbar from '../../components/Navbar';

const API_KEY = "sk-proj-QbN5TzH77tLhSQg9S24NT3BlbkFJQAm0pXoFU7z4DMt4NI3F";
const systemMessage = {
  "role": "system",
  "content": "Explain things like you're talking to a software professional with 2 years of experience."
};

const ChatGPT = () => {
  const [messages, setMessages] = useState([
    {
      message: "Hello, I'm ChatGPT! Ask me anything!",
      sentTime: "just now",
      sender: "ChatGPT"
    }
  ]);

  const [isTyping, setIsTyping] = useState(false);

  const handleSend = async (message) => {
    const newMessage = {
      message,
      direction: 'outgoing',
      sender: "user"
    };

    const newMessages = [...messages, newMessage];
    setMessages(newMessages);
    setIsTyping(true);

    try {
      await processMessageToChatGPT(newMessages);
    } catch (error) {
      console.error('Error sending message:', error);
      // Implement retry logic or error handling as needed
    }
  };

  const processMessageToChatGPT = async (chatMessages, retries = 0) => {
    let apiMessages = chatMessages.map((messageObject) => {
      let role = messageObject.sender === "ChatGPT" ? "user" : "assistant";
      return { role: role, content: messageObject.message };
    });

    const apiRequestBody = {
      "model": "gpt-3.5-turbo",
      "messages": [
        systemMessage,
        ...apiMessages
      ]
    };

    try {
      const response = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Authorization": "Bearer " + API_KEY,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(apiRequestBody)
      });

      if (!response.ok) {
        throw new Error('Failed to fetch');
      }

      const data = await response.json();
      console.log(data);

      if (data.choices && data.choices.length > 0 && data.choices[0].message) {
        setMessages([...chatMessages, {
          message: data.choices[0].message.content,
          sender: "ChatGPT"
        }]);
      } else {
        console.error('Unexpected response format:', data);
      }
      setIsTyping(false);
    } catch (error) {
      console.error('Error processing message:', error);

      // Retry logic
      if (retries < 3) { // Maximum 3 retries
        await new Promise(resolve => setTimeout(resolve, Math.pow(2, retries) * 1000)); // Exponential backoff
        await processMessageToChatGPT(chatMessages, retries + 1);
      } else {
        console.error('Maximum retry attempts exceeded.');
        setIsTyping(false);
        // Handle or notify the user of the error
      }
    }
  };

  return (
    <>
      <Navbar />
      <div style={{ margin: '50px 0px' }}>
        <div style={{ position: "relative", height: "500px", width: "700px", margin: 'auto' }}>
          <MainContainer>
            <ChatContainer>
              <MessageList style={{ justifyContent: 'flex-start', marginTop: '10px' }}
                scrollBehavior="smooth"
                typingIndicator={isTyping ? <TypingIndicator content="ChatGPT is typing" /> : null}
              >
                {messages.map((message, i) => (
                  <Message
                    style={{ justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start', color: message.sender === 'user' ? 'blue' : 'green' }}
                    key={i}
                    model={message}
                    sender={message.sender}
                  />
                ))}
              </MessageList>
              <MessageInput placeholder="Type message here" onSend={handleSend} />
            </ChatContainer>
          </MainContainer>
        </div>
      </div>
    </>
  );
};

export default ChatGPT;
