import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import ScrollAdd from "../components/ScrollAdd";
import "../style.css";
import Slider from "../components/slider/Slider";
import FactSection from "../components/FactSection";
import { Button, Grid, Typography } from "@mui/material";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
// import FloatingEnrollButton from "../components/floatingenrollButton/FloatingEnrollButton";
import HiringPartners from "../components/HiringPartners";
import Animations from "../components/Animations";

// import SpeedDialComponent from "../components/floatingenrollButton/SpeedDialComponent";

const Home = () => {
  const videoIds = [
    "NprbdgKM1d0?si=cFhQzUfg0c_iVc9h",
    "jECp0-_1snQ?si=ujOk6PE4yH8DcR5t",
    "ruc46tWiRh4?si=hoxnT_z_1krIRdhd",
    "p2rylmtKAts?si=Gh8IFInzUtUBta00",
  ];

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      const animatables = document.querySelectorAll(".animatable");

      animatables.forEach((animatable) => {
        const elementTop = animatable.getBoundingClientRect().top + scrollY;
        const elementBottom = elementTop + animatable.clientHeight;

        // Check if element is in the viewport
        if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
          animatable.classList.add("animated");
          animatable.classList.remove("animatable");
        } else {
          // Reset the animation when the element is out of view
          animatable.classList.remove("animated");
          animatable.classList.add("animatable");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Trigger animation on mount

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [showButton, setShowButton] = useState(false);
  const [showTranslation, setShowTranslation] = useState(false);

  const handleMouseEnter = () => {
    setShowButton(true);
  };

  const handleMouseLeave = () => {
    setShowButton(false);
    setShowTranslation(false); // Hide translation on mouse leave
  };

  const handleTranslateClick = () => {
    setShowTranslation(true);
  };

  return (
    <>
      <ScrollAdd />
      <Navbar />
      {/* <FloatingEnrollButton /> */}
      {/* <SpeedDialComponent /> */}
      <Slider />
      <FactSection />

      <Grid container className="about-main-box block animatable bounceInLeft">

        <Grid
  item
  sm={12}
  md={6}
  lg={6}
  xl={6}
  style={{ margin: "auto", padding: "5px" }}
>
  <Typography style={{ textAlign: "justify" }}>
    <h1
      style={{
        margin: "auto",
        textAlign: "center",
        fontFamily: "Samarkan, sans-serif",
      }}
      id="font-color"
    >
      Welcome to Learnorama Technologies
    </h1>
    <h3>Your Child's Learning Adventure Starts Here</h3>
    At <strong>Learnorama Technologies</strong>, we believe in nurturing young minds with <strong>innovative, technology-driven education</strong>. We offer a unique blend of <strong>online and offline learning experiences</strong> to prepare your child for the future. Our courses include cutting-edge technologies such as <strong>Artificial Intelligence (AI)</strong> and <strong>coding</strong>, as well as <strong>STEM education</strong>, alongside engaging <strong>art and science classes</strong>.
    <br />
    We also provide <strong>Artificial Intelligence-driven solutions</strong> for companies and organizations, including <strong>AI marketing</strong>, <strong>SEO</strong>, <strong>web design</strong>, <strong>content writing</strong>, <strong>marketing strategies</strong>, and <strong>product management</strong>. Our expertise empowers businesses to thrive in today's <strong>digital landscape</strong>.
    <br />
    But that's not all! We host <strong>adventure and life skills camps</strong>, fostering <strong>personal growth</strong>, <strong>confidence</strong>, and <strong>teamwork</strong>. In the 2025 academic year, we’re excited to introduce <strong>video-documented classes</strong> for the <strong>ICSE</strong> and <strong>CBSE curricula</strong>, providing a comprehensive and interactive learning platform for your child’s <strong>academic success</strong>.
  </Typography>
</Grid>


        <Grid
          item
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{ margin: "auto", textAlign: "center" }}>
          <div className="profile-wrapper" style={{ margin: "auto" }}>
            <div className="profile">
              <div className="profile-image">
                <img src="./images/ABOU-11.png" alt="About" />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>

      <Typography>
        <h1
          style={{ margin: "auto", textAlign: "center", color: "#000" }}
          className="block animatable bounceInRight">
          UPCOMING EVENT
        </h1>
      </Typography>

      <Grid
        container
        className="offlinecamp-maingrid block animatable bounceInLeft">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="offlinecamp-b1">
          <img src="./images/ADV.png" alt="success" />
          <h1 className="fest-heading">
            WINTER QUEST ADVENTURE AND LIFE SKILLS CAMP
          </h1>
          <p>EVENT ORGANISED BY LEARNORAMA.COM PVT LTD</p>
          <p>VENUE: GD GOENKA SCHOOL LB NAGAR</p>
          <Link to="/winterquestcamp" style={{ textDecoration: "none" }}>
            {" "}
            <Button
              // class="btn-hover color-3"
              style={{ padding: "12px 20px", borderRadius: "20px" }}
              className="button-2"
              variant="contained"
              color="primary">
              Learn More
            </Button>
          </Link>
        </Grid>
      </Grid>

      <Grid container className="about-main-box">
        <Grid
          item
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{ margin: "auto", padding: "5px" }}>
          <Typography
            style={{ textAlign: "justify" }}
            className="block animatable bounceInLeft">
            <h2 id="font-color">
              Why Choose Learnorama Technologies?
            </h2>
            <ul>
              <li>
                <strong>Innovative Curriculum</strong>: Our courses are designed
                to provide a strong foundation in{" "}
                <strong>Artificial Intelligence (AI)</strong>, tailored
                specifically for children aged 9 to 17. We focus on{" "}
                <strong>hands-on learning</strong>, creativity, and{" "}
                <strong>real-world applications</strong>.
              </li>
              <li>
                <strong>Expert Instructors</strong>: Learn from{" "}
                <strong>experienced professionals</strong> who are passionate
                about <strong>AI education</strong>. Our instructors are
                dedicated to guiding each student on their learning journey.
              </li>
              <li>
                <strong>Success Stories</strong>: Our students have achieved
                amazing things! From creating <strong>business websites</strong>{" "}
                to developing
                <strong>AI-powered projects</strong>, our courses empower
                students to turn their ideas into reality.
              </li>
            </ul>
            <h3 id="font-color">Explore Our Courses</h3>
            <ul>
              <li>
                <strong>AI Fundamentals</strong>: Start with the basics and
                build a solid understanding of <strong>AI concepts</strong>.
              </li>
              <li>
                <strong>Advanced AI Projects</strong>: Dive deeper into{" "}
                <strong>AI applications</strong> with advanced projects,
                suitable for ages 9 to 17.
              </li>
              <li>
                <strong>Interactive Workshops</strong>: Participate in{" "}
                <strong>hands-on workshops</strong>
                that offer practical experience with <strong>
                  AI tools
                </strong>{" "}
                and technologies.
              </li>
            </ul>
            <h3 id="font-color">Join Our Community</h3>
            <p>
              Be a part of a vibrant community of{" "}
              <strong>young learners</strong> and <strong>innovators</strong>.
              Collaborate, share ideas, and get inspired by peers and mentors.
            </p>
          </Typography>
        </Grid>

        <Grid
          item
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{ margin: "auto", textAlign: "center" }}>
          <div
            className="profile-wrapper block animatable bounceInRight"
            style={{ margin: "auto" }}>
            <div className="profile">
              <div className="profile-image ">
                <img src="./images/homepg-22.jpg" alt="About" />
              </div>
            </div>
          </div>
          {/* <p className="tagline">
            "इस देश के हर कोने और हर घर तक एआई की शक्ति पहुंचाने के लिए सिर्फ 1
            रुपये में – हर छात्र के भविष्य को उज्जवल बनाने के लिए।"
          </p> */}

          <p
            className="tagline block animatable bounceInRight"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ position: "relative", display: "inline-block" }}>
            "इस देश के हर कोने और हर घर तक एआई की शक्ति पहुंचाने के लिए सिर्फ 1
            रुपये में – हर छात्र के भविष्य को उज्जवल बनाने के लिए।"
            {showButton && (
              <button
                onClick={handleTranslateClick}
                style={{
                  position: "absolute",
                  right: 0,
                  bottom: "100%",
                  backgroundColor: "#fd7e14",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                  padding: "5px 10px",
                  borderRadius: "5px",
                }}>
                Translate
              </button>
            )}
          </p>
          {showTranslation && (
            <p className="translation">
              "To bring the power of AI to every corner and every home in this
              country for just 1 rupee – to brighten the future of every
              student."
            </p>
          )}

          <Link style={{ textDecoration: "none" }} to="/registration">
            <button
              style={{ marginTop: "15px", fontWeight: "bold" }}
              className="button-2 block animatable bounceInRight">
              Enroll Today
            </button>
          </Link>
        </Grid>
      </Grid>

      <Grid container className="about-main-box block animatable bounceInLeft">
        <Grid
          item
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{ margin: "auto", padding: "5px" }}>
          <Typography style={{ textAlign: "justify" }}>
            <h1
              style={{
                margin: "auto",
                textAlign: "center",
              }} id="font-color">
              Key Highlights of Learnorama Technologies
            </h1>

            <ul className="feature-list">
              <li>
                <strong style={{ fontWeight: "bold" }}>
                  Technology-Driven Learning:
                </strong>{" "}
                From{" "}
                <strong style={{ fontWeight: "bold" }}>
                  Artificial Intelligence (AI)
                </strong>{" "}
                to <strong style={{ fontWeight: "bold" }}>STEM education</strong>,
                we equip your child with the essential{" "}
                <strong style={{ fontWeight: "bold" }}>
                  skills for the future
                </strong>
                .
              </li>
              <li>
                <strong style={{ fontWeight: "bold" }}>
                  AI Solutions for Businesses:
                </strong>{" "}
                Comprehensive services including{" "}
                <strong style={{ fontWeight: "bold" }}>
                  AI marketing strategies
                </strong>
                , <strong style={{ fontWeight: "bold" }}>SEO services</strong>,{" "}
                <strong style={{ fontWeight: "bold" }}>web design</strong>, and
                more.
              </li>
              <li>
                <strong style={{ fontWeight: "bold" }}>
                  Interactive Online and Offline Camps:
                </strong>{" "}
                Offering fun and engaging{" "}
                <strong style={{ fontWeight: "bold" }}>adventure camps</strong> and{" "}
                <strong style={{ fontWeight: "bold" }}>
                  life skills training programs
                </strong>
                .
              </li>
              <li>
                <strong style={{ fontWeight: "bold" }}>
                  Comprehensive Curriculum for 2025:
                </strong>{" "}
                Launching{" "}
                <strong style={{ fontWeight: "bold" }}>
                  ICSE and CBSE curriculum video classes
                </strong>{" "}
                to make{" "}
                <strong style={{ fontWeight: "bold" }}>learning accessible</strong>{" "}
                and enjoyable.
              </li>
              <li>
                <strong style={{ fontWeight: "bold" }}>
                  Holistic Development:
                </strong>{" "}
                Focus on both{" "}
                <strong style={{ fontWeight: "bold" }}>academic excellence</strong>{" "}
                and <strong style={{ fontWeight: "bold" }}>personal growth</strong>.
              </li>
            </ul>
          </Typography>
        </Grid>

        <Grid
          item
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{ margin: "auto", textAlign: "center" }}>
          <div className="profile-wrapper" style={{ margin: "auto" }}>
            <div className="profile">
              <div className="profile-image">
                <img
                  src="./images/chess-01.png"
                  alt="About Learnorama Technologies"
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>

      {/*youtube videos start  */}

      <Typography>
        <h1
          style={{ margin: "auto", textAlign: "center" }}
          className="block animatable bounceInLeft">
          Latest News
        </h1>
      </Typography>

      <div className="Home-video-grid-main-box">
        <div className="Home-video-grid">
          {videoIds.map((id) => (
            <div className="Home-video-wrapper" key={id}>
              <iframe
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${id}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube video"></iframe>
            </div>
          ))}
        </div>
      </div>

      <HiringPartners />

      {/* youtube videos end  */}

      {/* ------------------------------------------------------------------- */}

      {/* contact start */}

      <Grid
        container
        className="about-main-box block animatable bounceInLeft"
        id="venue-box"
        style={{
          backgroundColor: "#000",
          padding: "20px 10px",
          borderRadius: "15px",
        }}>
        <Grid
          item
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{ margin: "auto", padding: "5px" }}>
          <Typography
            style={{ textAlign: "center" }}
            className="heading-main-box">
            <h2 style={{ margin: "auto", textAlign: "center", color: "#000" }}>
              Contact Learn Orama Technologies PVT LTD
            </h2>
            <div className="contact-details">
              <p>
                <a
                  style={{ textDecoration: "none", color: "#fff" }}
                  href="mailto:info@learnorama.in">
                  <span>📧 Email:</span>info@learnorama.in
                </a>
              </p>
              <p>
                <p>
                  <a
                    style={{ textDecoration: "none", color: "#fff" }}
                    href="tel:+91 9849856143">
                    <span>☎️ Phone:</span>+91 9849856143{" "}
                    <span style={{ padding: "5px" }}>|</span>
                  </a>
                  <a
                    style={{ textDecoration: "none", color: "#fff" }}
                    href="tel:+91 9949567143">
                    +91 9949567143 <span style={{ padding: "5px" }}>|</span>
                  </a>
                  <a
                    style={{ textDecoration: "none", color: "#fff" }}
                    href="tel:+91 9959945143">
                    +91 9959945143
                  </a>
                </p>
              </p>
            </div>
            <p style={{ color: "#000" }} className="connect-message">
              Connect with us and give your child an unforgettable summer full
              of learning, growth, and fun!
            </p>
          </Typography>
        </Grid>

        <Grid
          item
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{ margin: "auto", textAlign: "center" }}>
          <div className="profile-wrapper-contact" style={{ margin: "auto" }}>
            <div className="profile-contact">
              <div className="profile-image-contact">
                <img
                  style={{ width: "100%" }}
                  src="./images/contact-h1-01.png"
                  alt="About"
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>

      {/* contact end */}

      {/* ------------------------------------------------------------------- */}

      {/* Footer */}

      <Footer />
    </>
  );
};

export default Home;
