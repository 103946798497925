import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";

const WinterQuestCamp = () => {
  return (
    <>
      <Navbar />

      <div className="camp-container">
        <div className="cult-img-div">
        <img src='./images/quest2.png' alt="Winter Quest Camp" />
        </div>

        <h1 style={{ color: '#fd7e14' }}>Winter Quest: Adventure and Life Skills Camp</h1>
        <p style={{ textAlign: 'center', fontWeight: 'bold', color: '#fd7e14' }}>Exclusive for GD Goenka School, Hyderabad</p>
        <p style={{ textAlign: 'center', fontWeight: 'bold' }}>For 9 to 15 years age students</p>
        <p style={{ textAlign: 'center', fontWeight: 'bold' }}>October 2-4, 2024</p>

        <h1>Event Overview</h1>
        <div style={{ textAlign: 'center', margin: '20px 0' }}>
          <a 
            href="https://learnoramabackend.learnorama.in/pdfs/WINTER%20QUEST.pdf" 
            download 
            className="button-2"
            style={{
              display: 'inline-block',
              padding: '10px 20px',
              backgroundColor: '#fd7e14',
              color: '#fff',
              textDecoration: 'none',
              borderRadius: '5px',
              fontSize: '16px',
              fontWeight: 'bold'
            }}
          >
            Download PDF
          </a>
        </div>

        <h2 style={{ color: '#fd7e14' }}>Activities</h2>
        <ul>
          <li><strong>Camping:</strong> Learn how to set up tents, understand camping basics, and experience sleeping under the stars in a safe and fun environment.</li>
          <li><strong>Hut Construction:</strong> Build your own hut using natural materials found in the forest, fostering creativity and teamwork.</li>
          <li><strong>Cooking:</strong> Master the art of outdoor cooking and learn how to prepare delicious meals using campfire techniques.</li>
          <li><strong>Rock Climbing:</strong> Challenge yourself with rock climbing activities, developing strength, confidence, and problem-solving skills.</li>
          <li><strong>River Kayaking:</strong> Navigate through the serene waters while learning kayaking techniques and water safety practices.</li>
          <li><strong>Trekking:</strong> Explore the scenic trails of Vikarabad Forest, enhancing your endurance and appreciation for nature.</li>
          <li><strong>Free Flow Arts:</strong> Unleash your creativity with free-flowing art sessions, encouraging self-expression through various artistic mediums.</li>
          <li><strong>Campfire:</strong> Enjoy evening campfire sessions filled with storytelling, singing, and bonding activities, creating lasting memories with new friends.</li>
        </ul>

        <h2 style={{ color: '#fd7e14' }}>Schedule</h2>
        <h3>Day 1: October 2</h3>
        <ul>
          <li><strong>8:00 AM:</strong> Arrival at GD Goenka School
            <ul>
              <li>Registration and Welcome</li>
              <li>Briefing about the camp and safety instructions</li>
            </ul>
          </li>
          <li><strong>9:00 AM - 12:00 PM:</strong> Demonstrations
            <ul>
              <li>Introduction to Camping Gear</li>
              <li>Basic Survival Skills</li>
            </ul>
          </li>
          <li><strong>12:00 PM - 1:00 PM:</strong> Lunch Break</li>
          <li><strong>1:00 PM - 3:00 PM:</strong> Departure to Vikarabad Forest
            <ul>
              <li>Travel and Settle at Camp Site</li>
            </ul>
          </li>
          <li><strong>3:00 PM - 5:00 PM:</strong> Tent Setup and Camping Basics</li>
          <li><strong>5:00 PM - 6:00 PM:</strong> Evening Tea and Snacks</li>
          <li><strong>6:00 PM - 8:00 PM:</strong> Introduction to Campfire Activities</li>
          <li><strong>8:00 PM - 9:00 PM:</strong> Dinner</li>
          <li><strong>9:00 PM - 10:00 PM:</strong> Night Campfire and Storytelling</li>
          <li><strong>10:00 PM:</strong> Lights Out</li>
        </ul>

        <h3>Day 2: October 3</h3>
        <ul>
          <li><strong>6:00 AM - 7:00 AM:</strong> Morning Exercise and Warm-up</li>
          <li><strong>7:00 AM - 8:00 AM:</strong> Breakfast</li>
          <li><strong>8:00 AM - 10:00 AM:</strong> Hut Construction</li>
          <li><strong>10:00 AM - 12:00 PM:</strong> Rock Climbing</li>
          <li><strong>12:00 PM - 1:00 PM:</strong> Lunch</li>
          <li><strong>1:00 PM - 3:00 PM:</strong> River Kayaking</li>
          <li><strong>3:00 PM - 5:00 PM:</strong> Trekking</li>
          <li><strong>5:00 PM - 6:00 PM:</strong> Evening Tea and Snacks</li>
          <li><strong>6:00 PM - 8:00 PM:</strong> Free Flow Arts Session</li>
          <li><strong>8:00 PM - 9:00 PM:</strong> Dinner</li>
          <li><strong>9:00 PM - 10:00 PM:</strong> Campfire Activities</li>
          <li><strong>10:00 PM:</strong> Lights Out</li>
        </ul>

        <h3>Day 3: October 4</h3>
        <ul>
          <li><strong>6:00 AM - 7:00 AM:</strong> Morning Exercise and Warm-up</li>
          <li><strong>7:00 AM - 8:00 AM:</strong> Breakfast</li>
          <li><strong>8:00 AM - 10:00 AM:</strong> Cooking Skills</li>
          <li><strong>10:00 AM - 12:00 PM:</strong> Adventure Activities
            <ul>
              <li>Choice of Rock Climbing, Kayaking, or Trekking</li>
            </ul>
          </li>
          <li><strong>12:00 PM - 1:00 PM:</strong> Lunch</li>
          <li><strong>1:00 PM - 3:00 PM:</strong> Group Activities and Team Building</li>
          <li><strong>3:00 PM - 4:00 PM:</strong> Pack Up and Clean Up</li>
          <li><strong>4:00 PM - 6:00 PM:</strong> Departure to GD Goenka School</li>
          <li><strong>6:00 PM - 7:00 PM:</strong> Certification Program and Closing Ceremony
            <ul>
              <li>Distribution of Certificates</li>
              <li>Group Photos and Farewell</li>
            </ul>
          </li>
        </ul>


        <h2 style={{ color: '#fd7e14' }}>Entry Fee</h2>
        <p>The entry fee for the Winter Quest: Adventure & Life Skills Camp is ₹7000, which includes lodging and boarding for the entire duration of the camp.</p>
        <p>Note: Additional charges apply for special video shoots for students (candidate trailer video for each student).</p>

        <h1 style={{ color: "#fd7e14" }}>
          Get a Closer Look: Watch Our Camp Video
        </h1>

        <div
          className="video-wrapper"
          style={{ margin: "auto", textAlign: "center" }}>
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/T1z8iSIKCio?si=_9NS9wV0RJ-DVG5u`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="YouTube video"></iframe>
        </div>


        <div style={{ textAlign: 'center', margin: '20px 0' }}>
        <Link style={{ textDecoration: "none" }} to="/enrollment">
            <button
              style={{ marginTop: "15px", fontWeight: "bold",padding:'15px 25px' }}
              className="button-2">
              Enroll Today
            </button>
          </Link>
          </div>


        <h2>Contact Information</h2>
        <ul>
          <li style={{textAlign:'center'}}>
            <strong>Phone:</strong> 9849856143, 9949567143, 9959945143
          </li>
          <li style={{textAlign:'center'}}>
            <strong>Website:</strong>{" "}
            <a
              href="http://www.learnorama.in"
              target="_blank"
              rel="noopener noreferrer">
              www.learnorama.in
            </a>
          </li>
        </ul>
      </div>

      <Footer />
    </>
  );
};

export default WinterQuestCamp;
