import React from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DrawerComponent from "./Drawer";
import { Stack } from "@mui/material";
import "./navbar.css";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";

import MenuIcon from "@material-ui/icons/Menu";
import ProfileIcon from "@material-ui/icons/AccountCircle";

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(18),
    display: "flex",
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
    color: "#fff",
    fontSize: "15px",
    margin:'auto',
    marginLeft: theme.spacing(5),
    borderBottom: "1px solid transparent",
    "&:hover": {
      color: "#000",
      fontSize: "15.5px",

    },
  },
  profileImg: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    objectFit: "cover",
    marginLeft: theme.spacing(2),
  },
}));

function Navbar() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <AppBar
      position="sticky"
      style={{ padding: "10px 10px" }}
      className="navbar">
      <CssBaseline />
      <Toolbar style={{ justifyContent: "space-between" }}>
        <Link to="/" className={classes.link}>
          <Stack>
            <img
              className="Nav-logo"
              src="./images/LEARNORAMA-LOGO.png"
              alt="main-logo"
            />
          </Stack>
        </Link>
        {isMobile ? (
          <DrawerComponent />
        ) : (
          <div className={classes.navlinks}>
            <Link
              to="/"
              className={classes.link}
              id="heading1"
              style={{ marginLeft: "30px" }}>
              HOME
            </Link>
            <Link
              to="/about"
              className={classes.link}
              id="heading1"
              style={{ marginLeft: "30px" }}>
              ABOUT US
            </Link>
            

            <Link
              to="/contact"
              className={classes.link}
              id="heading1"
              style={{ marginLeft: "30px" }}>
              CONTACT
            </Link>

            <Link
              to="/more"
              className={classes.link}
              id="heading1"
              style={{ marginLeft: "30px" }}>
              MORE
            </Link>

          {/* Profile Image */}
        <Link to="/login" className={classes.link}><div style={{ display: "flex", justifyContent: "flex-end",margin:'auto',textAlign:'center',justifyContent:'center',alignItems:'center' }}>
          <img src="./images/homepg-22.jpg" alt="Profile" className={classes.profileImg} />
        </div>
        <p>Login / Register</p></Link>

          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}
export default Navbar;
