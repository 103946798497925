import React from "react";
import "./Nutrition.css";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

const BelowFifteenYears = () => {
  return (
    <>
    <Navbar />
    
    <div className="meal-plan-container">
      <h2 className="age-group-title">Nutrition For Below 15 Years</h2>

      <div className="meal-day">
        <h3 className="day-title">Monday</h3>
        <div className="meal-type">
          <p className="meal-type-title"><strong>Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Whole grain waffles with fresh fruit - <em>Healthy and delicious.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Mixed nuts - <em>Energy-boosting and nutritious.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Chickpea salad with veggies - <em>Protein-rich and balanced.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Yogurt with granola - <em>Filling and healthy.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Vegetable curry with basmati rice - <em>Hearty and flavorful.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Calming and helps with sleep.</em></li>
          </ul>
          <p className="meal-type-title"><strong>Non-Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Oatmeal with eggs and spinach - <em>Nutritious and satisfying.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Cheese and apple slices - <em>Tasty and protein-rich.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Chicken stir-fry with vegetables - <em>Balanced and delicious.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Smoothie with banana and protein powder - <em>Nutrient-packed and refreshing.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Beef tacos with lettuce and salsa - <em>Hearty and flavorful.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Helps with relaxation and sleep.</em></li>
          </ul>
        </div>
      </div>

      <div className="meal-day">
        <h3 className="day-title">Tuesday</h3>
        <div className="meal-type">
          <p className="meal-type-title"><strong>Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Smoothie bowl with berries and chia seeds - <em>Rich in antioxidants and fiber.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Carrot sticks with hummus - <em>Crunchy and nutritious.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Lentil soup with whole grain bread - <em>Filling and healthy.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Apple slices with almond butter - <em>Sweet and satisfying.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Stuffed bell peppers with quinoa - <em>Nutritious and delicious.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Calming and aids sleep.</em></li>
          </ul>
          <p className="meal-type-title"><strong>Non-Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Breakfast burrito with eggs and chicken - <em>High in protein and tasty.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Greek yogurt with honey - <em>Sweet and nutritious.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Turkey and cheese sandwich with vegetables - <em>Balanced and delicious.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Smoothie with spinach and fruit - <em>Healthy and refreshing.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Grilled chicken with mashed sweet potatoes - <em>Hearty and nutritious.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Helps with relaxation and sleep.</em></li>
          </ul>
        </div>
      </div>

      <div className="meal-day">
        <h3 className="day-title">Wednesday</h3>
        <div className="meal-type">
          <p className="meal-type-title"><strong>Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Chia seed pudding with mixed fruit - <em>Rich in nutrients and delicious.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Almonds and dried apricots - <em>Energy-boosting and nutritious.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Vegetable stir-fry with tofu - <em>Protein-rich and flavorful.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Greek yogurt with fruit - <em>Protein-packed and refreshing.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Pasta with marinara sauce and a side salad - <em>Hearty and satisfying.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Calming and helps with sleep.</em></li>
          </ul>
          <p className="meal-type-title"><strong>Non-Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Eggs and bacon - <em>Protein-rich and hearty.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Cheese and crackers - <em>Tasty and satisfying.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Chicken Caesar salad - <em>Lean protein and fresh greens.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Smoothie with protein powder - <em>Nutrient-rich and tasty.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Beef stir-fry with brown rice - <em>Hearty and delicious.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Helps with relaxation and sleep.</em></li>
          </ul>
        </div>
      </div>

      <div className="meal-day">
        <h3 className="day-title">Thursday</h3>
        <div className="meal-type">
          <p className="meal-type-title"><strong>Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Whole grain toast with avocado - <em>Healthy fats and fiber.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Fresh fruit salad - <em>Sweet and nutritious.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Veggie wrap with hummus - <em>Fresh and balanced.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Rice cakes with peanut butter - <em>Crunchy and satisfying.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Stuffed mushrooms with quinoa - <em>Nutritious and flavorful.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Calming and helps with sleep.</em></li>
          </ul>
          <p className="meal-type-title"><strong>Non-Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Sausage and egg muffins - <em>High in protein and filling.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Greek yogurt with fruit - <em>Sweet and nutritious.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Turkey sandwich with vegetables - <em>Balanced and delicious.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Protein bar - <em>Energy-boosting and easy.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Grilled shrimp with vegetables - <em>Healthy and satisfying.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Helps with relaxation and sleep.</em></li>
          </ul>
        </div>
      </div>

      <div className="meal-day">
        <h3 className="day-title">Friday</h3>
        <div className="meal-type">
          <p className="meal-type-title"><strong>Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Fruit salad with yogurt - <em>Refreshing and nutritious.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Whole grain crackers with cheese - <em>Crunchy and satisfying.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Veggie burger with a side salad - <em>Delicious and balanced.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Smoothie with berries and spinach - <em>Healthy and energizing.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Black bean tacos with fresh salsa - <em>Flavorful and nutritious.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Helps with relaxation and sleep.</em></li>
          </ul>
          <p className="meal-type-title"><strong>Non-Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Breakfast sandwich with egg and turkey - <em>High in protein and delicious.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Trail mix - <em>Energy-boosting and tasty.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Tuna salad with whole grain crackers - <em>Protein-rich and balanced.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Smoothie with protein powder - <em>Nutrient-rich and satisfying.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> BBQ chicken with roasted vegetables - <em>Hearty and flavorful.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Helps with relaxation and sleep.</em></li>
          </ul>
        </div>
      </div>

      <div className="meal-day">
        <h3 className="day-title">Saturday</h3>
        <div className="meal-type">
          <p className="meal-type-title"><strong>Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Avocado toast with tomatoes - <em>Healthy fats and fresh veggies.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Fruit smoothie - <em>Refreshing and nutrient-packed.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Grilled vegetable sandwich - <em>Delicious and nutritious.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Rice cakes with almond butter - <em>Crunchy and satisfying.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Vegetable stir-fry with tofu - <em>Protein-rich and balanced.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Calming and helps with sleep.</em></li>
          </ul>
          <p className="meal-type-title"><strong>Non-Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Chicken sausage with scrambled eggs - <em>Protein-rich and filling.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Greek yogurt with honey - <em>Sweet and nutritious.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> BBQ chicken wrap - <em>Delicious and satisfying.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Cheese sticks - <em>High in protein and easy.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Turkey meatloaf with mashed potatoes - <em>Hearty and comforting.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Helps with relaxation and sleep.</em></li>
          </ul>
        </div>
      </div>

      <div className="meal-day">
        <h3 className="day-title">Sunday</h3>
        <div className="meal-type">
          <p className="meal-type-title"><strong>Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Smoothie bowl with granola - <em>Nutritious and delicious.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Mixed fruit - <em>Sweet and refreshing.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Stuffed zucchini boats - <em>Healthy and filling.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Carrot and celery sticks with hummus - <em>Crunchy and nutritious.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Eggplant Parmesan - <em>Hearty and flavorful.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Calming and aids sleep.</em></li>
          </ul>
          <p className="meal-type-title"><strong>Non-Vegetarian:</strong></p>
          <ul className="meal-list">
            <li className="meal-item"><strong>Breakfast:</strong> Omelette with mushrooms and cheese - <em>Protein-rich and tasty.</em></li>
            <li className="meal-item"><strong>Mid-morning Snack:</strong> Hard-boiled eggs - <em>Simple and nutritious.</em></li>
            <li className="meal-item"><strong>Lunch:</strong> Grilled salmon with quinoa - <em>High in protein and omega-3s.</em></li>
            <li className="meal-item"><strong>Afternoon Snack:</strong> Yogurt with fruit - <em>Sweet and satisfying.</em></li>
            <li className="meal-item"><strong>Dinner:</strong> Roast chicken with roasted vegetables - <em>Comforting and nutritious.</em></li>
            <li className="meal-item"><strong>Before Bed:</strong> Warm milk - <em>Helps with relaxation and sleep.</em></li>
          </ul>
        </div>
      </div>

    </div>
    
    <Footer />
    
    </>
  );
};

export default BelowFifteenYears;
