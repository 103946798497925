import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const AiMarketing = () => {
  return (
    <>
      <Navbar />
      
      <div className="packages-container">
        
       
        
        <h1>AI-Driven Digital Marketing</h1>
        <h2>Online Marketing Packages</h2>

         {/* SEO-Optimized Introduction */}
         <p>
          In today’s rapidly evolving <strong>digital marketing landscape</strong>, having a <strong>strong online presence</strong> is essential for businesses of all sizes. At Learnorama, we offer <strong>AI-driven digital marketing solutions</strong> designed to boost your visibility and drive traffic. Whether you're a <strong>startup</strong> aiming to establish your brand or a <strong>large business</strong> seeking to scale your online efforts, our tailored <strong>online marketing packages</strong> provide the <strong>best web design</strong>, <strong>SEO services</strong>, <strong>social media management</strong>, and <strong>AI-powered marketing</strong>. Explore our <strong>Silver</strong>, <strong>Gold</strong>, and <strong>Diamond packages</strong> to take your <strong>digital presence</strong> to the next level and thrive in the competitive world of <strong>digital marketing</strong>.
        </p>

        {/* Silver Package */}
        <div className="packages-grid">
          <div className="package-box">
            <img
              src="./images/silver-star.png"
              alt="Silver Package"
              className="package-image"
            />
            <h2>Silver Package: Basic Package</h2>
            <p><strong>Cost:</strong> ₹25,000 advance, ₹25,000 monthly</p>
            <p>This package is ideal for startups or small businesses looking to establish a strong online presence.</p>
            <ul>
              <p><strong>Web Design and Maintenance</strong>
                <ul>
                  <li>Basic Website Design (up to 10 pages)</li>
                  <li>Monthly Updates (up to 5 hours)</li>
                  <li>Security Checks and Backups</li>
                </ul>
              </p>
              <p><strong>Content Writing</strong>
                <ul>
                  <li>4 SEO-Optimized Blog Posts per month</li>
                </ul>
              </p>
              <p><strong>Multimedia Graphics</strong>
                <ul>
                  <li>20 Social Media Graphics per month</li>
                  <li>1 Promotional Graphic per month</li>
                </ul>
              </p>
              <p><strong>SEO Work</strong>
                <ul>
                  <li>On-Page SEO</li>
                  <li>Monthly SEO Reporting</li>
                  <li>2 Backlinks per month</li>
                </ul>
              </p>
              <p><strong>Digital Marketing</strong>
                <ul>
                  <li>Basic Social Media Management (3 posts/week)</li>
                </ul>
              </p>
              <p><strong>AI Marketing</strong>
                <ul>
                  <li>AI-Driven Analytics</li>
                  <li>1 AI-Generated Video per month</li>
                </ul>
              </p>
            </ul>
          </div>

          {/* Gold Package */}
          <div className="package-box">
            <img
              src="./images/gold-star.png"
              alt="Gold Package"
              className="package-image"
            />
            <h2>Gold Package: Standard Package</h2>
            <p><strong>Cost:</strong> ₹25,000 advance, ₹35,000 monthly</p>
            <p>This package is ideal for medium-sized businesses that require a more dynamic online presence.</p>
            <ul>
              <p><strong>Web Design and Maintenance</strong>
                <ul>
                  <li>Standard Website Design (up to 15 pages)</li>
                  <li>Monthly Updates and Optimization (up to 10 hours)</li>
                </ul>
              </p>
              <p><strong>Content Writing</strong>
                <ul>
                  <li>6 Advanced Blog Posts per month (800 to 1000 words)</li>
                </ul>
              </p>
              <p><strong>Multimedia Graphics</strong>
                <ul>
                  <li>32 Social Media Graphics per month</li>
                  <li>2 Flyers and 3 Videos per month</li>
                </ul>
              </p>
              <p><strong>SEO Work</strong>
                <ul>
                  <li>Comprehensive SEO (on-page and off-page)</li>
                  <li>Monthly SEO Reporting</li>
                  <li>5 Backlinks per month</li>
                </ul>
              </p>
              <p><strong>Digital Marketing</strong>
                <ul>
                  <li>Social Media and Paid Ads (3-4 platforms)</li>
                  <li>Email Marketing (1 campaign per month)</li>
                </ul>
              </p>
              <p><strong>AI Marketing</strong>
                <ul>
                  <li>Advanced AI Strategies</li>
                  <li>2 AI-Generated Videos per month</li>
                </ul>
              </p>
            </ul>
          </div>

          {/* Diamond Package */}
          <div className="package-box">
            <img
              src="./images/diamond-star.png"
              alt="Diamond Package"
              className="package-image"
            />
            <h2>Diamond Package: Premium Package</h2>
            <p><strong>Cost:</strong> ₹30,000 advance, ₹40,000 monthly</p>
            <p>This package is designed for larger businesses with ambitious growth goals.</p>
            <ul>
              <p><strong>Web Design and Maintenance</strong>
                <ul>
                  <li>Premium Website Design (up to 20 pages)</li>
                  <li>Comprehensive Updates (up to 15 hours)</li>
                </ul>
              </p>
              <p><strong>Content Writing</strong>
                <ul>
                  <li>8 Premium Blog Posts per month (1000 to 1500 words)</li>
                </ul>
              </p>
              <p><strong>Multimedia Graphics</strong>
                <ul>
                  <li>40 Social Media Graphics per month</li>
                  <li>3 Flyers and 2 Videos per month</li>
                </ul>
              </p>
              <p><strong>SEO Work</strong>
                <ul>
                  <li>Full SEO Suite</li>
                  <li>Advanced SEO Reporting</li>
                  <li>8 Backlinks per month</li>
                </ul>
              </p>
              <p><strong>Digital Marketing</strong>
                <ul>
                  <li>Extensive Social Media and Paid Ad Management</li>
                  <li>2 Email Marketing campaigns per month</li>
                </ul>
              </p>
              <p><strong>AI Marketing</strong>
                <ul>
                  <li>AI-Driven Marketing Campaigns</li>
                  <li>10 AI-Generated Videos per month</li>
                </ul>
              </p>
            </ul>
          </div>
        </div>
        
        {/* SEO-Optimized Conclusion */}
        <p>
          By leveraging the power of <strong>AI</strong> and <strong>advanced digital marketing strategies</strong>, Learnorama ensures your business stays ahead in the competitive market. From <strong>SEO-optimized content</strong>, <strong>on-page SEO</strong>, and <strong>backlink building</strong> to <strong>AI-driven video marketing</strong> and <strong>social media marketing</strong>, our packages offer comprehensive solutions to enhance your <strong>online presence</strong> and achieve your business goals. Let us help you boost your <strong>Google rankings</strong>, drive <strong>organic traffic</strong>, and grow your brand with our expert <strong>digital marketing services</strong> tailored to deliver real, measurable results.
        </p>
      </div>

      <Footer />
    </>
  );
};

export default AiMarketing;
