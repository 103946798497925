import React from 'react'
import ScrollAdd from '../../components/ScrollAdd'
import Navbar from '../../components/Navbar'
import Slider from '../../components/slider/Slider'
import FactSection from '../../components/FactSection'
import Footer from '../../components/Footer'

const Blogpg4 = () => {
  return (
    <>
    <ScrollAdd />
      <Navbar />
      <Slider />
      <FactSection />
      <div className="success-stories-container">
      <h1 className="success-stories-title">Beyond the Classroom: Success Stories from Learnorama</h1>
      <div style={{margin:'auto',textAlign:'center'}}>
      <img src="./images/blog4-img-newscut.jpeg" alt="Annika Reddy" className="blog-post-image" />
      </div>
      <div className="success-story">
        <h2 className="student-name">Adharva Reddy</h2>
        <p className="student-info">A budding star at Meridian School, Adharva holds the title of the youngest world record holder for go-karting at the age of 6. His achievements continue to inspire young minds at Learnorama.</p>
      </div>
      <div className="success-story">
        <h2 className="student-name">Advith Reddy</h2>
        <p className="student-info">From Oakridge International School, Advith is a passionate learner who broke the previous world record in chess, previously held by Manchu Nirvana, daughter of Tollywood actress Manchu Lakshmi, at the age of 4.5 years. His dedication and determination set him apart as a true champion.</p>
      </div>
      <div className="success-story">
        <h2 className="student-name">Annika Reddy</h2>
        <p className="student-info">A shining star at Meridian School, Annika is the world's youngest paraglider, setting records in 2021. Her adventurous spirit and perseverance inspire others to reach for the stars.</p>
      </div>
      <div className="success-story">
        <h2 className="student-name">Rimitha Reddy</h2>
        <p className="student-info">A student at Delhi Public School, Rimith is a chess prodigy who became the youngest trainer certified by the Noble Book of World Records in 2020. His passion for chess and teaching motivates others to excel in the game.</p>
      </div>
      <p className="success-stories-conclusion">These success stories exemplify the spirit of Learnorama – where education meets adventure and every child is empowered to reach their full potential. Join us on the journey to success this summer!</p>
    </div>

      <Footer />
    </>
  )
}

export default Blogpg4