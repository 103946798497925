

import React from 'react'
import ScrollAdd from '../../components/ScrollAdd'
import Navbar from '../../components/Navbar'
import Slider from '../../components/slider/Slider'
import FactSection from '../../components/FactSection'
import Footer from '../../components/Footer'

const Blogpg5 = () => {
  return (
    <>
    <ScrollAdd />
      <Navbar />
      <Slider />
      <FactSection />
      <div className="success-stories-container">
      <h1 className="success-stories-title">Beyond the Classroom: Success Stories from Learnorama</h1>
      <div style={{margin:'auto',textAlign:'center'}}>
      <img src="./images/blog4-img.jpeg" alt="Annika Reddy" className="blog-post-image" />
      </div>
      <div className="blog-post">
      <h1>🌟 Unlocking Creativity</h1>
      <p>At the heart of our adventure is the spirit of creativity. Through a carefully crafted lineup of activities, we aim to unlock the boundless imagination of each child. Whether it's capturing the perfect shot in junior photography or weaving tales in digital storytelling, every moment is a chance to explore, create, and innovate.</p>

      <h2>🎨 Art and Craft Innovations</h2>
      <p>Dive into a world of colors, textures, and endless possibilities! From traditional crafts to modern techniques, children will discover the joy of creating something truly unique. Whether they're molding clay, painting masterpieces, or designing their own creations, our art and craft sessions are sure to inspire budding artists.</p>

      <h2>🔬 Science Explorations</h2>
      <p>Spark curiosity and ignite a love for science through hands-on experiments and investigations. From concocting crazy chemical reactions to exploring the wonders of the natural world, our science sessions are as enlightening as they are fun. Who knows, the next great scientist might just be in our midst!</p>

      <h2>🌎 Exploring New Worlds</h2>
      <p>Journey with us as we embark on exciting adventures to unknown lands. Through interactive games, storytelling, and immersive experiences, children will broaden their horizons and develop a deeper understanding of the world around them. Every day brings a new discovery, a new adventure waiting to unfold.</p>

      <h2>🚀 Developing Essential Skills</h2>
      <p>While having a blast, children will also hone essential skills that will serve them well beyond the summer. Communication, teamwork, problem-solving—these are just a few of the invaluable abilities they'll cultivate during their time with us. Learning is seamless when it's this much fun!</p>

      <h2>🌟 Making Lifelong Memories</h2>
      <p>Beyond the activities and lessons, our camp is a place where friendships are forged, laughter fills the air, and memories are made to last a lifetime. Whether it's roasting marshmallows by the campfire, collaborating on a group project, or simply sharing stories under the stars, every moment is a treasure.</p>

      <p>Join us at Learnorama's Primary School Adventure, where imagination knows no bounds and every day is an opportunity to discover, create, and explore! 🌟</p>
      <p>Let the adventure begin! 🚀</p>
    </div>
    </div>

      <Footer />
    </>
  )
}

export default Blogpg5