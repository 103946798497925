import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const ParentsTalk = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      const animatables = document.querySelectorAll('.animatable');

      animatables.forEach(animatable => {
        const elementTop = animatable.getBoundingClientRect().top + scrollY;
        const elementBottom = elementTop + animatable.clientHeight;

        // Check if element is in the viewport
        if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
          animatable.classList.add('animated');
          animatable.classList.remove('animatable');
        } else {
          // Reset the animation when the element is out of view
          animatable.classList.remove('animated');
          animatable.classList.add('animatable');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Trigger animation on mount

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const testimonials = [
    {
      text: "“My kids enjoyed a lot in the summer camp..and learned chess,dance and over come stage fear..thank you staff”",
      client: "- KAMALA RAMUNI",
      img: "./images/testimonials-img1-100x100.jpg",
    },
    {
      text: "“Summer camp is very Good, we are Happy to participate in summar camp, for dance and other activities  like chess it starting first time my son enjoyed very lot he gane some knowledge also.”",
      client: "- Sheshasaina Reddy",
      img: "./images/testimonials-img2-100x100.jpg",
    },
    {
      text: "“It was a wonderful experience with learnorama It was more innovative Kids enjoyed and had very good time during summer vacation They have overcome stage fear It was a best summer camp never had before”",
      client: "- chandana Porika",
      img: "./images/testimonials-img3-100x100.jpg",
    },
    {
      text: "“Very well organised, disciplined and engaged kids in many activities even education trip  was conducted  . Excited to join in next year too . Thank you learnorama”",
      client: "- Sahaja Reddy",
      img: "./images/testimonials-img3-100x100.jpg",
    },
    {
      text: "“Summer Camp from learnorama is very good for engaging kids as well as helped them to grow in chess public speaking and dance”",
      client: "- Vamshi Khanday",
      img: "./images/testimonials-img3-100x100.jpg",
    },
    {
      text: "“It was a good exposure for my son T.Vishruth Reddy. He was very happy about the project plant growth.”",
      client: "- Mallikarjun Reddy",
      img: "./images/testimonials-img3-100x100.jpg",
    },
    {
      text: "“It was nice and joy experience for my kid we liked it thank you learnorama we are glad we opted for it”",
      client: "- Roja Vantedhu",
      img: "./images/testimonials-img3-100x100.jpg",
    },
    {
      text: "“Satisfied. Excellent learning in learnorama... Worthy summer camp. We are happy in joining learnorama summer camp.”",
      client: "- Swati Reddy.D",
      img: "./images/testimonials-img3-100x100.jpg",
    },
    {
      text: "“Organised very well as a parent am very happy with my son's performance in all the activities which they trained Thank you”",
      client: "- Harini Peddi",
      img: "./images/testimonials-img3-100x100.jpg",
    },
  ];

  // Function to get a random index for the testimonials
  const getRandomIndex = () => {
    let newIndex = Math.floor(Math.random() * testimonials.length);
    return newIndex === activeIndex ? (newIndex + 1) % testimonials.length : newIndex;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(getRandomIndex());
    }, 3000); // Change testimonial every 3 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [activeIndex]);

  return (
    <>
      <Navbar />
    
      <section className="parentsTalk-section">
        <div className="parentsTalk-container">
          <div className="parentsTalk-heading">
            <h2 className="block animatable bounceInLeft">PARENT'S TALK</h2>
          </div>

          <div className="parentsTalk-testimonialsSection">
            <div className="parentsTalk-sliderPagination block animatable bounceInLeft">
              {testimonials.map((_, index) => (
                <span
                  key={index}
                  className={`pagination-bullet ${index === activeIndex ? 'active' : ''}`}
                  role="button"
                  aria-label={`Go to slide ${index + 1}`}
                  onClick={() => setActiveIndex(index)}
                ></span>
              ))}
            </div>

            <div className="parentsTalk-testimonials">
              <div className="testimonial-item active block animatable bounceInLeft">
                <p>{testimonials[activeIndex].text}</p>
                <div className="testimonial-client">
                  {/* <img src={testimonials[activeIndex].img} alt={testimonials[activeIndex].client} /> */}
                  <p style={{color:'#000'}}>{testimonials[activeIndex].client}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Google Reviews Button */}
          <div className="google-reviews-container">
            <a 
              href="https://maps.app.goo.gl/hZrvDSYaoQzwdDDC7" 
              className="google-reviews-button" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              Google Reviews
            </a>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default ParentsTalk;
