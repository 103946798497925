import React from 'react'
import ScrollAdd from '../../components/ScrollAdd'
import Navbar from '../../components/Navbar'
import Slider from '../../components/slider/Slider'
import FactSection from '../../components/FactSection'
import Footer from '../../components/Footer'

const Blogpg2 = () => {
  return (
    <>
    <ScrollAdd />
      <Navbar />
      <Slider />
      <FactSection />

      <div className="blog-post-container">
      <h1 className="blog-post-title">Adharva Reddy: A Journey of Sporting Excellence</h1>
      <img src="./images/adharva-bg1.jpeg" alt="Adharva Reddy" className="blog-post-image" />
      <div className="blog-post-content">
        <p>Adharva Reddy is a remarkable young talent whose extraordinary achievements have left an indelible mark in the world of sports and beyond. At the tender age of 6, Adharva made headlines as the youngest go-karting racer, showcasing his exceptional skills and determination. His record-breaking feat of driving 10 kilometers in just 17 minutes and 32 seconds not only earned him recognition but also inspired awe and admiration among enthusiasts worldwide.</p>
        <p>But Adharva's journey to greatness didn't stop there. At the age of 8, he embarked on another remarkable endeavor, this time in the realm of archery. Displaying unparalleled focus and precision, Adharva shattered the previous world record by hitting an astonishing 1111 arrows in just 3 hours and 10 minutes. This incredible feat not only demonstrated his mastery of the sport but also solidified his status as a prodigious talent.</p>
        <p>In recognition of his exceptional performance and groundbreaking achievements, Adharva Reddy was honored with the prestigious Krida Bhushan Puraskar. This esteemed award serves as a testament to Adharva's unwavering dedication, relentless pursuit of excellence, and unparalleled talent.</p>
        <p>Adharva's remarkable journey serves as an inspiration to aspiring athletes and young minds everywhere, proving that with passion, perseverance, and unwavering determination, anything is possible. As he continues to carve his path to greatness, Adharva Reddy is poised to leave an enduring legacy in the world of sports and beyond.</p>
      </div>
    </div>
      <Footer />
    </>
  )
}

export default Blogpg2