import { Grid } from '@material-ui/core';
import React from 'react'
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

const OfflineCamps = () => {
  return (
    <>

    <Navbar />
    {/* venue start */}

    <div className="heading-main-box">
        <h5 style={{ color: "#000" }}>Learnorama</h5>
        <h1>Tech Generation Summer Camp For Kids 2024</h1>
        <h3>Our Venues</h3>
        {/* <span style={{ fontSize: "16px" }}>(5th to 9th class)</span> */}
      </div>
<div>

<Grid container style={{width:'80%',margin:'auto',padding:'10px'}}  className="about-main-box-page">
        
        <Grid
          item
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{ margin: "auto", textAlign: "center",padding:'10px' }} className="venue-item-box">
          <div className="profile-wrapper-events" style={{ margin: "auto" }}>
            <div className="profile-events">
              <div className="profile-image-events">
                <img src="./images/MeridianPIC.jpg" alt="About" />
              </div>
            </div>
          </div>
          <div className="heading-main-box" style={{marginTop:'30px'}}>
          <h1>Tech Generation Summer Camp For Kids 2024 At <span style={{color:'#fd7e14'}}>MERIDIAN SCHOOL (UPPAL)</span></h1>
          <img src="./images/MERIDIANLOGO.jpg" alt="About" />
            
            <h5>Dates: 29th April 2024 - 29th May 2024</h5>
            </div>
          <Link style={{ textDecoration: "none" }} to="/Meridian">
            <button style={{ marginTop: "15px",fontWeight:'bold' }} className="button-2">
            Activities
            </button>
          </Link>
        </Grid>
        
        <Grid
          item
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{ margin: "auto", textAlign: "center",padding:'10px' }} className="venue-item-box">
          <div className="profile-wrapper-events" style={{ margin: "auto" }}>
            <div className="profile-events">
              <div className="profile-image-events">
                <img src="./images/Gdgoenka-PIC.jpg" alt="About" />
              </div>
            </div>
          </div>
          <div className="heading-main-box" style={{marginTop:'30px'}}>
          <h1>Tech Generation Summer Camp For Kids 2024 At <span style={{color:'#fd7e14'}}>GD GOENKA SCHOOL</span></h1>
          <img src="./images/GDLOGO.PNG" alt="About" />
            
            <h5>Dates: 29th April 2024 - 29th May 2024</h5>
            </div>
          <Link style={{ textDecoration: "none" }} to="/gd">
            <button style={{ marginTop: "15px",fontWeight:'bold' }} className="button-2">
            Activities
            </button>
          </Link>
        </Grid>
        
      </Grid>

      <Grid container style={{width:'80%',margin:'auto',padding:'10px'}}  className="about-main-box-page">
        
        <Grid
          item
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{ margin: "auto", textAlign: "center",padding:'10px' }} className="venue-item-box">
          <div className="profile-wrapper-events" style={{ margin: "auto" }}>
            <div className="profile-events">
              <div className="profile-image-events">
                <img src="./images/DACE.jpg" alt="About" />
              </div>
            </div>
          </div>
          <div className="heading-main-box" style={{marginTop:'30px'}}>
          <h1>RHYTHMIC BEATS SUMMER CAMP For Kids 2024 At <span style={{color:'#fd7e14'}}> MERIDIAN SCHOOL (UPPAL)</span></h1>
          <img src="./images/MERIDIANLOGO.jpg" alt="About" />
            
            <h5>Dates: 29th April 2024 - 29th May 2024</h5>
            </div> 
          
          <Link style={{ textDecoration: "none" }} to="/rhythmic">
            <button style={{ marginTop: "15px",fontWeight:'bold' }} className="button-2">
            Activities
            </button>
          </Link>
        </Grid>
        
        <Grid
          item
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{ margin: "auto", textAlign: "center",padding:'10px' }} className="venue-item-box">
          <div className="profile-wrapper-events" style={{ margin: "auto" }}>
            <div className="profile-events">
              <div className="profile-image-events">
                <img src="./images/Adventurecamp.jpg" alt="About" />
              </div>
            </div>
          </div>
          <div className="heading-main-box" style={{marginTop:'30px'}}>
          <h1>ADVENTURES SUMMER CAMP 2024 AT <span style={{color:'#fd7e14'}}>PEOPLES SCHOOL OF EXCELLENCE</span></h1>
          <img id="ppl-img" src="./images/PSELOGOFINAL1.png" alt="About" />
            
            <h5>Dates: 13th May 2024 - 26th May 2024</h5>
            </div>
            
          
          <Link style={{ textDecoration: "none" }} to="/peopleadv">
            <button style={{ marginTop: "15px",fontWeight:'bold' }} className="button-2">
            Activities
            </button>
          </Link>
        </Grid>
        
      </Grid>

</div>

<Footer />
      
    </>
  )
}

export default OfflineCamps;