import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Grid from "@mui/material/Grid";

const More = () => {
  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      const animatables = document.querySelectorAll(".animatable");

      animatables.forEach((animatable) => {
        const elementTop = animatable.getBoundingClientRect().top + scrollY;
        const elementBottom = elementTop + animatable.clientHeight;

        // Check if element is in the viewport
        if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
          animatable.classList.add("animated");
          animatable.classList.remove("animatable");
        } else {
          // Reset the animation when the element is out of view
          animatable.classList.remove("animated");
          animatable.classList.add("animatable");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Trigger animation on mount

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar />

      {/* Courses and Services */}
      <div className="more-main-heading">
        <h1>Courses and Services</h1>
      </div>
      <Grid container spacing={2} justifyContent="center" textAlign="center">
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Link to="/registration" className="more-box animatable block animatable bounceInLeft">
            <img
              src="/images/more--01.jpg"
              alt="Learnorama: Online Courses, Website Design, and Maintenance"
              className="more-box-image"
            />
            <h1 className="more-box-title" style={{ color: "#fff" }}>
              Learnorama: Online Courses
            </h1>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Link to="/Website" className="more-box animatable block animatable bounceInLeft">
            <img
              src="/images/more--02.jpg"
              alt="Website Design and Maintenance"
              className="more-box-image"
            />
            <h1 className="more-box-title" style={{ color: "#fff" }}>
              Website Design and Maintenance
            </h1>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Link to="/aimarketing" className="more-box animatable block animatable bounceInLeft">
            <img
              src="/images/more--03.jpg"
              alt="AI-Driven Digital Marketing"
              className="more-box-image"
            />
            <h1 className="more-box-title" style={{ color: "#fff" }}>
              AI-Driven Digital Marketing
            </h1>
          </Link>
        </Grid>
      </Grid>

      {/* Blogs and Resources */}
      <div className="more-main-heading">
        <h1>Blogs and Resources</h1>
      </div>
      <Grid container spacing={2} justifyContent="center" textAlign="center">
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Link to="/blog" className="more-box animatable block animatable bounceInLeft">
            <img
              src="/images/more--04.jpg"
              alt="Latest Insights and Blog"
              className="more-box-image"
            />
            <h1 className="more-box-title" style={{ color: "#fff" }}>
              Latest Insights and Blog
            </h1>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Link to="/nutritionforkids" className="more-box animatable block animatable bounceInLeft">
            <img
              src="/images/more--05.jpg"
              alt="Health and Nutrition Tips for Children"
              className="more-box-image"
            />
            <h1 className="more-box-title" style={{ color: "#fff" }}>
              Health and Nutrition Tips for Children
            </h1>
          </Link>
        </Grid>
      </Grid>

      {/* Events and Activities */}
      <div className="more-main-heading">
        <h1>Events and Activities</h1>
      </div>
      <Grid container spacing={2} justifyContent="center" textAlign="center">
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Link to="/enrollment" className="more-box animatable block animatable bounceInLeft">
            <img
              src="/images/more--06.jpg"
              alt="Enrollments and Registration"
              className="more-box-image"
            />
            <h1 className="more-box-title" style={{ color: "#fff" }}>
              Enrollments and Registration
            </h1>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Link to="/winterquestcamp" className="more-box animatable block animatable bounceInLeft">
            <img
              src="/images/more--07.jpg"
              alt="Upcoming Events and Activities"
              className="more-box-image"
            />
            <h1 className="more-box-title" style={{ color: "#fff" }}>
              Upcoming Events and Activities
            </h1>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Link to="/offlinecamps" className="more-box animatable block animatable bounceInLeft">
            <img
              src="/images/more--08.jpg"
              alt="Previous Summer Camps Highlights"
              className="more-box-image"
            />
            <h1 className="more-box-title" style={{ color: "#fff" }}>
              Previous Summer Camps Highlights
            </h1>
          </Link>
        </Grid>
      </Grid>

      {/* Community and Testimonials */}
      <div className="more-main-heading">
        <h1>Community and Testimonials</h1>
      </div>
      <Grid container spacing={2} justifyContent="center" textAlign="center">
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Link to="/parentstalk" className="more-box animatable block animatable bounceInLeft">
            <img
              src="/images/more--09.jpg"
              alt="Testimonials and Gallery"
              className="more-box-image"
            />
            <h1 className="more-box-title" style={{ color: "#fff" }}>
              Parents Talk
            </h1>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Link to="/gallery" className="more-box animatable block animatable bounceInLeft">
            <img
              src="/images/more--10.jpg"
              alt="Testimonials and Gallery"
              className="more-box-image"
            />
            <h1 className="more-box-title" style={{ color: "#fff" }}>
              Gallery
            </h1>
          </Link>
        </Grid>
      </Grid>

      <Footer />
    </>
  );
};

export default More;
