import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import ScrollAdd from "../../components/ScrollAdd";
// import FloatingEnrollButton from "../../components/floatingenrollButton/FloatingEnrollButton";

const Gd = () => {
  return (
    <>
      <ScrollAdd />
      <Navbar />
      {/* <FloatingEnrollButton /> */}

      {/* ------------------------------------------------------------------- */}

      <div className="heading-main-box" style={{ marginTop: "20px" }}>
        <h5 style={{ color: "#000" }}>Learnorama</h5>
        <h1>Tech Generation Summer Camp For Kids 2024</h1>
        <h1>at GD GOENKA SCHOOL (LB NAGAR)</h1>
        <h3>High School Summer Camp Activities</h3>
        <span style={{ fontSize: "16px" }}>(5th to 9th class)</span>
      </div>

      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography className="activities-para-boxs">
            <img src="./images/downloadfile-35.jpg" alt="About" />
            <h1 style={{ fontSize: "22px", color: "#000" }}>
              Exploring AI tools <br />{" "}
              <span style={{ fontSize: "14px" }}>
                ("Unleash the Power of AI!")
              </span>
            </h1>
            <p>
            Dive into the world of AI with hands-on exploration of tools for image generation, voice cloning, and more.
            </p>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography className="activities-para-boxs">
            <img src="./images/downloadfile-51.png" alt="About" />
            <h1 style={{ fontSize: "22px", color: "#000" }}>
              AI in Coding <br />{" "}
              <span style={{ fontSize: "14px" }}>
                ("Code the Future with AI!")
              </span>
            </h1>

            <p>
            Explore the endless possibilities of coding with AI, from developing smart applications to optimizing algorithms for efficiency.
            </p>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography className="activities-para-boxs">
            <img src="./images/downloadfile-52.png" alt="About" />
            <h1 style={{ fontSize: "22px", color: "#000" }}>
              AI in Microsoft <br />{" "}
              <span style={{ fontSize: "14px" }}>
                ("Empower Your Work with AI!")
              </span>
            </h1>
            <p>
            Unlock the potential of AI features in Microsoft tools, from intelligent data analysis in Excel to interactive presentations in PowerPoint.
            </p>
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography className="activities-para-boxs">
            <img src="./images/AI-art.jpg" alt="About" />
            <h1 style={{ fontSize: "22px", color: "#000" }}>
              AI For Arts <br />{" "}
              <span style={{ fontSize: "14px" }}>
                ("Create Masterpieces with AI!")
              </span>
            </h1>
            <p>
            Unleash your creativity as AI algorithms transform images into stunning works of art, exploring the fusion of technology and artistic expression.
            </p>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography className="activities-para-boxs">
            <img src="./images/downloadfile-52.png" alt="About" />
            <h1 style={{ fontSize: "22px", color: "#000" }}>
              AI in Culinary Arts <br />{" "}
              <span style={{ fontSize: "14px" }}>
                ("Culinary Innovation with AI!")
              </span>
            </h1>

            <p>
            Delve into the future of cooking with AI-generated recipes from around the world, blending innovation with culinary creativity.
            </p>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography className="activities-para-boxs">
            <img src="./images/AI-MUSIC.jpg" alt="About" />
            <h1 style={{ fontSize: "22px", color: "#000" }}>
              AI in Generated Music
              <br />{" "}
              <span style={{ fontSize: "14px" }}>
                ("Compose with AI Beats!")
              </span>
            </h1>

            <p>
            Experience the magic of AI-generated music beats and melodies, creating original compositions in diverse styles.
            </p>
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography className="activities-para-boxs">
            <img src="./images/digital-story-telling.jpg" alt="About" />
            <h1 style={{ fontSize: "22px", color: "#000" }}>
              AI Marketing <br />{" "}
              <span style={{ fontSize: "14px" }}>
                ("Revolutionize with AI Insights!")
              </span>
            </h1>
            <p>
            Discover how AI is revolutionizing digital marketing strategies with data-driven insights and personalized campaigns.
            </p>
          </Typography>
        </Grid>
      </Grid>

      <div className="heading-main-box">
        <h3>Primary School Summer Camp Activities</h3>
        <span style={{ fontSize: "16px" }}>(1st to 4th class)</span>
      </div>

      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography className="activities-para-boxs">
            <img src="./images/downloadfile-53.jpg" alt="About" />
            <h1 style={{ fontSize: "22px", color: "#000" }}>
              Jr Photography <br />{" "}
              <span style={{ fontSize: "14px" }}>
                (Little Lens, Big Vision)
              </span>
            </h1>

            <p>
              Unleash the creative eye with Jr Photography! Where little lenses
              capture big visions, nurturing the next generation of
              photographers.
            </p>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography className="activities-para-boxs">
            <img src="./images/downloadfile-57.jpg" alt="About" />
            <h1 style={{ fontSize: "22px", color: "#000" }}>
              Digital Storytelling <br />{" "}
              <span style={{ fontSize: "14px" }}>
                (Narrate, Create, Innovate)
              </span>
            </h1>

            <p>
              Dive into the world of Digital Storytelling, where imagination
              meets innovation. Learn to narrate compelling tales, create
              engaging content, and innovate through the power of digital
              narratives.
            </p>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography className="activities-para-boxs">
            <img src="./images/downloadfile-54.png" alt="About" />
            <h1 style={{ fontSize: "22px", color: "#000" }}>
              Art and Craft Innovations <br />{" "}
              <span style={{ fontSize: "14px" }}>
                (Crafting Tomorrow's Wonders)
              </span>
            </h1>

            <p>
              Join the journey of Art and Craft Innovations, where young minds
              craft tomorrow's wonders. Explore creativity, express uniqueness,
              and foster innovative thinking through hands-on artistic
              endeavors.
            </p>
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography className="activities-para-boxs">
            <img src="./images/downloadfile-54.jpg" alt="About" />
            <h1 style={{ fontSize: "22px", color: "#000" }}>
              Science Experiments <br />{" "}
              <span style={{ fontSize: "14px" }}>
                (Hands-On Discovery, Minds-On Marvel)
              </span>
            </h1>

            <p>
              Embark on a hands-on journey of discovery with Science
              Experiments! Engage curious minds in marvels of science, fostering
              a love for exploration and learning through interactive
              experiments.
            </p>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography className="activities-para-boxs">
            <img src="./images/chess-gd.jpg" alt="About" />
            <h1 style={{ fontSize: "22px", color: "#000" }}>
              Chess <br />{" "}
              <span style={{ fontSize: "14px" }}>
                (Master the Board, Rule the Game)
              </span>
            </h1>

            <p>
              Conquer the chessboard and rule the game! Master strategic
              thinking, decision-making, and enhance cognitive skills through
              the timeless game of chess.
            </p>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography className="activities-para-boxs">
            <img src="./images/downloadfile-56.png" alt="About" />
            <h1 style={{ fontSize: "22px", color: "#000" }}>
              Communication Skills <br />{" "}
              <span style={{ fontSize: "14px" }}>
                (Speak with Impact, Connect with Influence)
              </span>
            </h1>

            <p>
              Elevate communication skills to new heights! Learn to speak with
              impact and connect with influence, empowering individuals to
              express themselves confidently and effectively in various aspects
              of life.
            </p>
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{ margin: "auto", padding: "15px" }}>
          <Typography className="activities-para-boxs">
            <img src="./images/dance.jpg" alt="About" />
            <h1 style={{ fontSize: "22px", color: "#000" }}>
              Dance <br />{" "}
              <span style={{ fontSize: "14px" }}>
                ("Move. Groove. Freely Express.")
              </span>
            </h1>
            <p>
            Break free from choreography and dance rules, allowing your imagination to flow freely on the dance floor in a vibrant fusion of styles
            </p>
          </Typography>
        </Grid>
      </Grid>

      {/* ------------------------------------------------------------------- */}

      <Footer />
    </>
  );
};

export default Gd;
