import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import './Vol01Chapters.css'; // Import the CSS file
import Footer from '../../../components/Footer';
import Navbar from '../../../components/Navbar';

const Vol01Chapters = () => {
  const [chapters, setChapters] = useState([]);
  const [videos, setVideos] = useState([]);
  const [error, setError] = useState(null);
  const [decryptedToken, setDecryptedToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const videosRef = useRef(null);

  const baseURL = 'https://learnoramabackend.learnorama.in/videos/';

  const decryptToken = () => {
    const encryptedToken = localStorage.getItem('authToken');
    if (encryptedToken) {
      try {
        const bytes = CryptoJS.AES.decrypt(encryptedToken, 'hello');
        return bytes.toString(CryptoJS.enc.Utf8);
      } catch (error) {
        console.error('Error decrypting the token:', error);
        setError('Failed to decrypt the token. Please login again.');
        return null;
      }
    } else {
      console.log('No token found in local storage');
      setError('No token found. Please login again.');
      return null;
    }
  };

  useEffect(() => {
    const token = decryptToken();
    if (token) {
      setDecryptedToken(token);
    }
  }, []);

  useEffect(() => {
    if (!decryptedToken) return;

    const fetchChapters = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          'https://learnoramabackend.learnorama.in/api/user/videos/V01',
          {
            headers: {
              Authorization: `Bearer ${decryptedToken}`,
            },
          }
        );

        console.log('API Response:', response);

        if (!response.data || !Array.isArray(response.data.videos)) {
          throw new Error('Invalid data format');
        }

        const uniqueChapters = Array.from(
          new Set(response.data.videos.map(video => video.chapter))
        ).map(chapter => {
          return response.data.videos.find(video => video.chapter === chapter);
        });

        // Sort chapters alphabetically
        uniqueChapters.sort((a, b) => a.chapter.localeCompare(b.chapter));

        setChapters(uniqueChapters);
      } catch (error) {
        console.error('Error fetching the data:', error);
        if (error.response && error.response.status === 401) {
          setError('Unauthorized. Please check your token.');
        } else {
          setError('Access denied. Please complete your payment before logging in to view video content.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchChapters();
  }, [decryptedToken]);

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  const handleChapterClick = async (chapterId) => {
    try {
      const response = await axios.get(
        `https://learnoramabackend.learnorama.in/api/user/videos/V01/${chapterId}`,
        {
          headers: {
            Authorization: `Bearer ${decryptedToken}`,
          },
        }
      );

      console.log('API Response:', response);

      if (response.data && response.data.videos && response.data.videos[chapterId]) {
        setVideos(response.data.videos[chapterId]);
        videosRef.current.scrollIntoView({ behavior: 'smooth' });
      } else {
        throw new Error('Invalid data format');
      }
    } catch (error) {
      console.error('Error fetching the data:', error);
      if (error.response && error.response.status === 401) {
        setError('Unauthorized. Please check your token.');
      } else {
        setError('Failed to fetch data. Unauthorized Entry please Do Successful payment before login to watch videos.');
      }
    }
  };

  const navigate = useNavigate();

  const handleVideoClick = (chapterId, videoId) => {
    navigate(`/lesson/${chapterId}/${videoId}`);
  };

  const handlePayment = async () => {
    try {
      const response = await axios.post(
        'https://learnoramabackend.learnorama.in/api/user/payment/init',
        {
          amount: 1,
          volume_id: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${decryptedToken}`,
          },
        }
      );
      console.log('Payment API Response:', response);
      if (response.data && response.data.redirectUrl) {
        window.location.href = response.data.redirectUrl;
      } else {
        throw new Error('Invalid payment response');
      }
    } catch (error) {
      console.error('Error initiating payment:', error);
      setError('Failed to initiate payment. Please try again later.');
    }
  };

  if (error) {
    return (
      <div className="error">
        Error: {error}
        <button onClick={handlePayment} className="button-payment">
          Complete Payment
        </button>
      </div>
    );
  }

  return (
    <>
      <Navbar />
      <div className="V-C-container">
        <h1>VIDEO CHAPTERS</h1>
        <p
        style={{
          textAlign: "center",
          fontWeight: "bold",
          background: "yellow",
          padding: "10px",
          width: "70%",
          margin: "auto",
          marginTop: "10px",
          marginBottom: "10px",
          color:'#000'
        }}>
        Note: Every student who subscribes to the course will undergo an online
        exam. Qualified students will receive a Jr. Prompt Engineer certificate.
      </p>
        {loading ? (
          <p className="loading">Loading...</p>
        ) : (
          <ul>
            {chapters.length > 0 ? (
              chapters.map(chapter => (
                <li key={chapter.chapter} className="chapter-list-item">
                  <img
                    src={`${baseURL}${chapter.image}`}
                    alt={chapter.title}
                    className="video-thumbnail"
                  />
                  <p className="chapter-details">Chapter: {chapter.chapter}</p>
                  <button onClick={() => handleChapterClick(chapter.chapter)} className="button-2">WATCH NOW</button>
                </li>
                
              ))
            ) : (
              <li>No chapters available.</li>
            )}
          </ul>
        )}

        <h1>Future Proof AI Skills: Junior Prompt Engineering- Volume 1 PDF</h1>
        <div style={{ textAlign: 'center', margin: '20px 0' }}>
          <a 
            href="https://learnoramabackend.learnorama.in/pdfs/Future%20Proof%20AI%20Skills%20Junior%20Prompt%20Engineering-%20Volume%201.pdf" 
            download 
            className="button-2"
            style={{
              display: 'inline-block',
              padding: '10px 20px',
              backgroundColor: '#fd7e14',
              color: '#fff',
              textDecoration: 'none',
              borderRadius: '5px',
              fontSize: '16px',
              fontWeight: 'bold'
            }}
          >
            Download PDF
          </a>
        </div>
        <h2 ref={videosRef}>VIDEOS</h2>
        <ul>
          {videos.length > 0 ? (
            videos.map(video => (
              <li
                key={video.lesson}
                className="video-list-item"
                onClick={() => handleVideoClick(video.chapter, video.lesson)}
              >
                <img
                  src={`${baseURL}${video.image}`}
                  alt={video.title}
                  className="video-thumbnail"
                />
                <div className="video-details">
                  <p>Chapter: {video.chapter}</p>
                  <p>Lesson: {video.lesson}</p>
                  <p>Title: {video.title}</p>
                </div>
              </li>
            ))
          ) : (
            <li>No videos available for this chapter.</li>
          )}
        </ul>
      </div>
      <Footer />
    </>
  );
};

export default Vol01Chapters;
