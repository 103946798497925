import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import './Nutrition.css'; // Ensure you have imported your CSS file

const NutritionForKids = () => {
  return (
    <>
      <Navbar />

      <h1 className="page-title">
        Nutrition For Kids
      </h1>

      <div className="age-groups-container">
        <Link
          style={{ textDecoration: "none", color: "#fff",margin:'auto' }}
          to="/belowsixyears">
          <div className="age-group-box">
            <h2>Below Six Years</h2>
          </div>
        </Link>

        <Link
          style={{ textDecoration: "none", color: "#fff",margin:'auto' }}
          to="/belowtenyears">
          <div className="age-group-box">
            <h2>Below Ten Years</h2>
          </div>
        </Link>

        <Link
          style={{ textDecoration: "none", color: "#fff",margin:'auto' }}
          to="/belowfifteenyears">
          <div className="age-group-box">
            <h2>Below Fifteen Years</h2>
          </div>
        </Link>
      </div>

      <Footer />
    </>
  );
};

export default NutritionForKids;
