import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
// import './RefundPolicy.css';

const RefundPolicy = () => {
  return (
    <>
    <Navbar />
    <div className="terms-and-conditions-container">
      <h1>Learn orama Refund Policy</h1>
      <p>At Learnorama, we are committed to providing high-quality AI education for children. We understand that sometimes, circumstances may require a refund. Please review our refund policy below:</p>
      
      <section>
        <h2>1. Course Enrollment Refunds</h2>
        <h3>1.1. Full Refunds</h3>
        <p>A full refund will be granted if a cancellation request is received within 48 hours of the course purchase date, provided that the student has not accessed more than 10% of the course content.</p>
        
        <h3>1.2. Partial Refunds</h3>
        <p>If a cancellation request is received after 48 hours but within 30 days of the course purchase date, and the student has not accessed more than 30% of the course content, a partial refund of 50% will be granted.</p>
        
        <h3>1.3. No Refunds</h3>
        <p>No refunds will be granted if the cancellation request is received more than 30 days after the course purchase date or if the student has accessed more than 30% of the course content.</p>
      </section>

      <section>
        <h2>2. Workshop and Event Refunds</h2>
        <h3>2.1. Full Refunds</h3>
        <p>A full refund will be granted if a cancellation request is received at least 7 days prior to the start date of the workshop or event.</p>
        
        <h3>2.2. Partial Refunds</h3>
        <p>A partial refund of 50% will be granted if a cancellation request is received within 7 days of the workshop or event start date.</p>
        
        <h3>2.3. No Refunds</h3>
        <p>No refunds will be granted if the cancellation request is received on or after the workshop or event start date.</p>
      </section>

      <section>
        <h2>3. Technical Issues</h2>
        <p>If you experience any technical issues that prevent you from accessing the course, please contact our support team immediately. We will work to resolve the issue promptly. If the issue cannot be resolved within a reasonable timeframe, a full or partial refund may be issued based on the extent of the access problem.</p>
      </section>

      <section>
        <h2>4. Special Circumstances</h2>
        <p>We understand that special circumstances may arise. If you believe you have a valid reason for a refund outside of the standard policy, please contact us at <a href="mailto:info@learnorama.in">info@learnorama.in</a> with details of your situation. Requests will be reviewed on a case-by-case basis.</p>
      </section>

      <section>
        <h2>5. Refund Process</h2>
        <p>To request a refund, please contact our support team at <a href="mailto:info@learnorama.in">info@learnorama.in</a> with your order details, including your name, course name, purchase date, and reason for the refund request. Refunds, if approved, will be processed within 14 business days and will be issued to the original payment method.</p>
      </section>

      <section>
        <h2>6. Changes to Refund Policy</h2>
        <p>Learnorama reserves the right to modify or update this refund policy at any time. Changes will be posted on this page, and we will notify enrolled students via email of any significant changes.</p>
      </section>

      <p>For any questions or concerns regarding our refund policy, please contact us at <a href="mailto:info@learnorama.in">info@learnorama.in</a>.</p>
    </div>

    <Footer />
    
    </>
  );
};

export default RefundPolicy;
