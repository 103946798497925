import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import { Link } from 'react-router-dom';

const Website = () => {
  return (
    <>
      <Navbar />
      <div className="projects-container">
        <h1>Website Design and Maintenance</h1>
        <h2>Our Recent Projects</h2>
        <p>
          At <strong>Learnorama Technologies Private Limited</strong>, we specialize in <strong>professional website design</strong>, <strong>SEO optimization</strong>, and <strong>AI-driven digital marketing strategies</strong> that help businesses grow their online presence and reach their target audience. Our team of experts in <strong>web development</strong> and <strong>digital marketing</strong> ensures that each project is tailored to meet client needs. Here's a look at some of our recent projects and the <strong>digital marketing techniques</strong> that have delivered exceptional results.
        </p>
        
      </div>

      <Link
        to="https://aekakshfranchise.com/"
        style={{ textDecoration: "none", color: "#000",marginTop:'30px' }}>
        <div className="website-container">
          <div className="website-left-box">
            <img
              src="./images/aekaksh-ss.png"
              alt="Aekaksh School"
              className="website-image"
            />
          </div>
          <div className="website-right-box">
            <h2>Aekaksh School Franchise Model</h2>
            <p>
              <strong>Project Overview:</strong> Developed a comprehensive
              business proposal for Aekaksh School to expand its network through
              a franchise model. The objective is to establish 20 new branches
              by the 2026 academic year.
            </p>
            <h3>Marketing Strategies:</h3>
            <ul>
              <li>
                <strong>Digital Marketing:</strong> Implemented targeted online
                marketing campaigns to attract potential franchisees using SEO,
                PPC advertising, and social media promotion.
              </li>
              <li>
                <strong>Content Creation:</strong> Created detailed and engaging
                content, such as promotional videos and brochures, to clearly
                communicate the benefits of joining the franchise model.
              </li>
              <li>
                <strong>AI Marketing:</strong> Leveraged AI tools to analyze
                market trends and optimize marketing strategies for reaching
                prospective investors and franchisees.
              </li>
            </ul>
          </div>
        </div>
      </Link>

      <Link
        to="https://gdgoenkahyd.com/"
        style={{ textDecoration: "none", color: "#000",marginTop:'30px' }}>
        <div className="website-container">
          <div className="website-left-box">
            <img
              src="./images/Gd-ss.png"
              alt="GD Goenka School"
              className="website-image"
            />
          </div>
          <div className="website-right-box">
            <h2>GD Goenka School - Hyderabad Branch</h2>
            <p><strong>Project Overview:</strong> Promoted GD Goenka School in LB Nagar, Hyderabad, featuring cutting-edge facilities like AI labs, high-end robotics labs, and international-standard classrooms.</p>
            <h3>Marketing Strategies:</h3>
            <ul>
              <li><strong>Comprehensive Digital Campaign:</strong> Executed multi-channel digital marketing across social media, search engines, and educational networks.</li>
              <li><strong>AI-Driven Marketing:</strong> Employed AI analytics to track user engagement and refine content to target prospective students and families.</li>
              <li><strong>Offline Promotion:</strong> Coordinated local events and open house sessions to showcase the school’s facilities and programs.</li>
            </ul>
          </div>
        </div>
      </Link>

      <Link
        to="https://soulsportza.com/"
        style={{ textDecoration: "none", color: "#000",marginTop:'30px' }}>
        <div className="website-container">
          <div className="website-left-box">
            <img
              src="./images/SOULSPORTZA-ss.png"
              alt="SOULSPORTZA"
              className="website-image"
            />
          </div>
          <div className="website-right-box">
            <h2>SOULSPORTZA - Sports and Fitness Initiatives</h2>
            <p><strong>Project Overview:</strong> Designed and managed various sports events and fitness programs, including the construction of a cross-fitness gym and swimming pool.</p>
            <h3>Marketing Strategies:</h3>
            <ul>
              <li><strong>Event Marketing:</strong> Promoted events through digital channels, local partnerships, and online advertising.</li>
              <li><strong>Content Creation:</strong> Produced multimedia content, including video highlights and testimonials, to attract attendees.</li>
              <li><strong>AI Analytics:</strong> Utilized AI tools to monitor event engagement and fine-tune marketing strategies for future events.</li>
            </ul>
          </div>
        </div>
      </Link>

      <Link
        to="https://www.swagridez.com"
        style={{ textDecoration: "none", color: "#000",marginTop:'30px' }}>
        <div className="website-container">
          <div className="website-left-box">
            <img
              src="./images/swag-ss.png"
              alt="Swag Ridez"
              className="website-image"
            />
          </div>
          <div className="website-right-box">
            <h2>Swag Ridez - Luxury Car Dealership</h2>
            <p><strong>Project Overview:</strong> Developed an online platform for Swag Ridez to connect buyers with high-quality pre-owned luxury cars.</p>
            <h3>Marketing Strategies:</h3>
            <ul>
              <li><strong>SEO and PPC:</strong> Optimized the website for search engine visibility and launched targeted PPC campaigns.</li>
              <li><strong>Social Media Advertising:</strong> Created targeted ads to reach luxury car enthusiasts and prospective buyers.</li>
              <li><strong>Content Development:</strong> Generated content, including vehicle highlights and testimonials, to showcase the Swag Ridez platform.</li>
            </ul>
          </div>
        </div>
      </Link>
      
      <div className="projects-container">
        <p>
          At Learnorama, we pride ourselves on our ability to create <strong>SEO-friendly websites</strong> and leverage <strong>AI-powered marketing solutions</strong> to help businesses grow. Whether you're looking for <strong>website design services</strong>, <strong>content marketing</strong>, or <strong>social media management</strong>, our team has the experience and tools to elevate your brand.
        </p>
      </div>
      
      <Footer />
    </>
  );
};

export default Website;
