import React from "react";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaInstagramSquare,
  FaWhatsappSquare,
  FaYoutubeSquare,
} from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <div
        style={{
          width: "100%",
          // backgroundColor: "#000089",
          background: "rgb(0,15,38)",
          background: "linear-gradient(137deg, rgb(241, 115, 30) 18%, rgb(231, 29, 54) 95%)",
          marginTop: "50px",
        }}>
        <Grid container className="footer-main-box">
          <Grid
            item
            className="footer-box1"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}>
            <img src="./images/LEARNORAMA-LOGO.png" />
          </Grid>

          <Grid
            item
            className="footer-box2"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}>
            <h2 style={{ color: "#000" }}>Short Link</h2>
            <Link
              style={{
                textDecoration: "none",
                color: "#fff",
                margin: "auto",
                textAlign: "center",
              }}
              to="/about">
              <p>About Us</p>
            </Link>

            <Link
              style={{
                textDecoration: "none",
                color: "#fff",
                margin: "auto",
                textAlign: "center",
              }}
              to="/contact">
              <p>Contact Us</p>
            </Link>

            <Link
              style={{
                textDecoration: "none",
                color: "#fff",
                margin: "auto",
                textAlign: "center",
              }}
              to="/enrollment">
              <p>Enrollment</p>
            </Link>

           
            <Link
              style={{
                textDecoration: "none",
                color: "#fff",
                margin: "auto",
                textAlign: "center",
              }}
              to="/parentstalk">
              <p>Parents Talk</p>
            </Link>
          </Grid>

          <Grid
            item
            className="footer-box3"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}>
            <h2 style={{ color: "#000" }}>Help Link</h2>
            <Link
              style={{
                textDecoration: "none",
                color: "#fff",
                margin: "auto",
                textAlign: "center",
              }}
              to="/termsAndConditions">
              <p>Terms and Conditions</p>
            </Link>

            <Link
              style={{
                textDecoration: "none",
                color: "#fff",
                margin: "auto",
                textAlign: "center",
              }}
              to="/privacyPolicy">
              <p>Privacy Policy</p>
            </Link>

            <Link
              style={{
                textDecoration: "none",
                color: "#fff",
                margin: "auto",
                textAlign: "center",
              }}
              to="/refundpolicy">
              <p>Refund Policy</p>
            </Link>


            

            <Link
              style={{
                textDecoration: "none",
                color: "#fff",
                margin: "auto",
                textAlign: "center",
              }}
              to="/faqs">
              <p>FAQ'S</p>
            </Link>
          </Grid>

          <Grid
            item
            className="footer-box3"
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}>
            <h2 style={{ color: "#000" }}>Follow Us</h2>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <a
                href="https://www.instagram.com/learno.rama?igsh=azA1MHIxNTE2Y3V3"
                target="_blank"
                rel="noopener noreferrer">
                <FaInstagramSquare
                  style={{ fontSize: 30, color: "#fff", margin: "0 10px" }}
                />
              </a>
              
              <a
                href="https://www.facebook.com/profile.php?id=61556653161005"
                target="_blank"
                rel="noopener noreferrer">
                <FaFacebookSquare
                  style={{ fontSize: 30, color: "#fff", margin: "0 10px" }}
                />
              </a>

              <a
                href="https://www.youtube.com/@Learnorama3"
                target="_blank"
                rel="noopener noreferrer">
                <FaYoutubeSquare
                  style={{ fontSize: 30, color: "#fff", margin: "0 10px" }}
                />
              </a>
              
              <a
                href="https://api.whatsapp.com/send?phone=9849856143"
                target="_blank"
                rel="noopener noreferrer">
                <FaWhatsappSquare
                  style={{ fontSize: 30, color: "#fff", margin: "0 10px" }}
                />
              </a>
              

              <a
                href="https://twitter.com/learnorama_in"
                target="_blank"
                rel="noopener noreferrer">
                <FaTwitterSquare
                  style={{ fontSize: 30, color: "#fff", margin: "0 10px" }}
                />
              </a>

            </div>
          </Grid>
        </Grid>
        <div
          style={{
            background: "rgb(0,15,38)",
          background: "linear-gradient(137deg, rgb(241, 115, 30) 18%, rgb(231, 29, 54) 95%)",
            margin: "auto",
            textAlign: "center",
            color: "#fff",
          }}>
          <p style={{textAlign:'center'}}>&copy; 2024 Learnorama.in. All rights reserved.</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
