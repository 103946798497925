import React from 'react'
import ScrollAdd from '../../components/ScrollAdd'
import Navbar from '../../components/Navbar'
import Slider from '../../components/slider/Slider'
import FactSection from '../../components/FactSection'
import Footer from '../../components/Footer'

const Blogpg3 = () => {
  return (
    <>
    <ScrollAdd />
      <Navbar />
      <Slider />
      <FactSection />

      <div className="blog-post-container">
      <h1 className="blog-post-title">Annika Reddy: A Journey of Passion and Fearlessness</h1>
      <img src="./images/arnika-bg-1.jpeg" alt="Annika Reddy" className="blog-post-image" />
      <div className="blog-post-content">
        <p>Annika Reddy's journey is a testament to the power of passion, determination, and resilience. From a young age, Annika displayed an innate talent and love for the game of chess, embarking on a journey that would lead her to remarkable heights of success. Her journey began with humble beginnings, but her dedication and hard work propelled her to become one of the most promising chess players of her generation.</p>
        <p>Annika's passion for chess was evident from the start, as she spent countless hours honing her skills and mastering the intricacies of the game. Her strategic thinking, sharp intellect, and unwavering focus set her apart on the chessboard, earning her numerous accolades and championships at the state and national levels.</p>
        <p>But Annika's journey didn't stop at the chessboard. Her thirst for adventure and exploration led her to new heights – quite literally. At a young age, Annika discovered her love for paragliding, embarking on daring flights that would ultimately lead her to set a remarkable world record. In 2021, Annika soared to new heights, becoming the world's youngest paraglider and setting records that captured the imagination of enthusiasts worldwide.</p>
        <p>Annika's journey is a testament to the power of passion, determination, and fearlessness. Her achievements in both chess and paragliding serve as an inspiration to aspiring athletes and adventurers everywhere, proving that with dedication, perseverance, and an unwavering belief in oneself, anything is possible.</p>
        <p>As Annika continues to push the boundaries of what is possible, her journey serves as a reminder that the sky is not the limit – it's just the beginning of endless possibilities. Her story is a testament to the indomitable spirit of human potential and the transformative power of following one's dreams.</p>
      </div>
    </div>

      <Footer />
    </>
  )
}

export default Blogpg3