

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
const CryptoJS = require('crypto-js');

const Volume01ChaptersLessons = () => {
  const { chapterId } = useParams();
  const [lessons, setLessons] = useState([]);
  const [error, setError] = useState(null);
  const [decryptedToken, setDecryptedToken] = useState(null);

  useEffect(() => {
    const encryptedToken = localStorage.getItem('authToken');
    if (encryptedToken) {
      try {
        const bytes = CryptoJS.AES.decrypt(encryptedToken, 'hello');
        const token = bytes.toString(CryptoJS.enc.Utf8);
        setDecryptedToken(token);
      } catch (error) {
        console.error('Error decrypting the token:', error);
        setError('Failed to decrypt the token. Please login again.');
      }
    } else {
      console.log('No token found in local storage');
      setError('No token found. Please login again.');
    }
  }, []);

  useEffect(() => {
    if (!decryptedToken || !chapterId) return;

    const fetchLessons = async () => {
      try {
        const response = await axios.get(
          `https://learnoramabackend.learnorama.in/api/user/videos/V01/${chapterId}`,
          {
            headers: {
              Authorization: `Bearer ${decryptedToken}`,
            },
          }
        );

        if (!response.data || !response.data.videos) {
          throw new Error('Invalid data format');
        }

        setLessons(response.data.videos[chapterId]);
      } catch (error) {
        console.error('Error fetching the data:', error);
        setError('Failed to fetch data. Please try again later.');
      }
    };

    fetchLessons();
  }, [decryptedToken, chapterId]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h1>Lessons for Chapter {chapterId}</h1>
      <ul>
        {lessons.length > 0 ? (
          lessons.map(lesson => (
            <li key={lesson.id}>
              <h2>{lesson.title}</h2>
              <p>Lesson: {lesson.lesson}</p>
              <a href={lesson.path} target="_blank" rel="noopener noreferrer">Watch Video</a>
            </li>
          ))
        ) : (
          <li>No lessons available.</li>
        )}
      </ul>
    </div>
  );
};

export default Volume01ChaptersLessons;

