import React, { useEffect } from "react";

const FactSection = () => {

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;

      const animatables = document.querySelectorAll(".animatable");

      animatables.forEach((animatable) => {
        const elementTop = animatable.getBoundingClientRect().top + scrollY;
        const elementBottom = elementTop + animatable.clientHeight;

        // Check if element is in the viewport
        if (scrollY + windowHeight > elementTop && scrollY < elementBottom) {
          animatable.classList.add("animated");
          animatable.classList.remove("animatable");
        } else {
          // Reset the animation when the element is out of view
          animatable.classList.remove("animated");
          animatable.classList.add("animatable");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Trigger animation on mount

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <div style={{ marginBottom: "100px" }} className="fact-container">
      <div className="fact-row">
        <div className="fact-item wow fadeIn" data-wow-delay=".1s">
          <div className="d-flex counter block animatable bounceInLeft">
            <h1 className="me-3 text-primary counter-value">1</h1>
            <h5 className="text-white mt-1">
              <span style={{color:'#000'}}>Unlock the Future with AI Education</span>: Enhance creativity and drive innovation with our <span style={{color:'#000'}}>AI learning programs</span>.
            </h5>
          </div>
        </div>
        <div className="fact-item wow fadeIn" data-wow-delay=".3s">
          <div className="d-flex counter block animatable bounceInRight">
            <h1 className="me-3 text-primary counter-value">2</h1>
            <h5 className="text-white mt-1">
              <span style={{color:'#000'}}>Innovate and Inspire with AI</span>: Equip young minds with <span style={{color:'#000'}}>cutting-edge AI skills</span> and prepare them for future technology challenges.
            </h5>
          </div>
        </div>
        <div className="fact-item wow fadeIn" data-wow-delay=".5s">
          <div className="d-flex counter block animatable bounceInLeft">
            <h1 className="me-3 text-primary counter-value">3</h1>
            <h5 className="text-white mt-1">
              <span style={{color:'#000'}}>Empowering Youth through Artificial Intelligence</span>: Discover the <span style={{color:'#000'}}>world of AI</span> and foster innovation among young learners.
            </h5>
          </div>
        </div>
        <div className="fact-item wow fadeIn" data-wow-delay=".7s">
          <div className="d-flex counter block animatable bounceInRight">
            <h1 className="me-3 text-primary counter-value">4</h1>
            <h5 className="text-white mt-1">
              <span style={{color:'#000'}}>Develop Tomorrow’s AI Leaders</span>: Explore, learn, and <span style={{color:'#000'}}>transform ideas into reality</span> with our comprehensive AI education programs.
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FactSection;
